import React, {
  Fragment,
  useContext,
  useState,
  useCallback,  
  lazy,
  Suspense,
  useEffect
} from "react";

import profileService from "../services/profile.service";
import Load from "../components/Load";
// import { PostsContext } from "../context/postsContext";
// import Gifts from "../components/Gifts";
// import Drawer from 'react-bottom-drawer';
import { AuthContext } from "../context/authContext";
import { useNavigate } from 'react-router-dom';
import Moments from "./Moments";
// import InfiniteScroll from "react-infinite-scroll-component";
import ChatDemo from "../components/chat/ChatDemo";
// import Hallfame from "../components/Hallfame";
import Appfooter from "../components/Appfooter";
import Auction from "../components/Auction";
// import Soccer from "../components/Soccer"
//  import Marquee from "react-fast-marquee";
// import FreeStyle from "../components/recorder/FreeStyle";
// import { Postview1 } from "../components/Postview";
import Flash from "../components/Flash";
import { startTransition } from 'react';

// import Swiper from "../components/Swiper";
const Postview = lazy(() => import("../components/Postview"));
const Hallfame = lazy(() => import("../components/Hallfame"));




export default function Home() {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  // const [exps, setExps] = useState(); 
   const [giftItem, setgiftItem] = useState(0); 
  // const myRef = useRef();
  const [gift, setGift] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  //const [ge, setGe] = useState(false);
  const openDrawer = useCallback(() => setIsVisible(true), [])
  const [hideFooter,setHideFooter] = useState(false);
  const closeDrawer = useCallback(() => {
    setIsVisible(false);
   // setGe(false);
  }, []);
  // const [flashMessage, setFlashmessage] = useState([]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  const sendGift = (item,usr) => {

    //console.log('itemid'+ item);
    openDrawer();
    setGift(!gift);
    
  }

  

  //const cdn = 'https://files.geetsuhane.com/';
  const cdn = 'https://files.geetsuhane.com/';
  const ssd = process.env.REACT_APP_API_END_POINT+'/gsfiles/';
  // const {  topList,getPosts,bday } = useContext(PostsContext);
  const [bday, setBday] = useState([]);
  const [more, setMore]=useState(true);
//console.log(flash);
const [postid, setPostId]=useState(null);

  const promo='https://files.geetsuhane.com/promo/promo.mp4'

//console.log(featurePost)

const getbday = async() => {
  const bdaydata = await profileService.getResult({         
      qry :`CALL sp_getPosts(0,0,0,3)`
    })
    setBday(bdaydata?.[0]);

}

useEffect(() => {
  const fetchData = async () => {
    await getbday();
  };
  
  fetchData();
}, []);


const postClicked = (pid) => {
  startTransition(() => {
    setPostId(pid);
  });
  //console.log(pid);
};

if(currentUser?.status===2) {
  // console.log(currentUser?.status)
  navigate(
    "/denied"
  )
}

  //console.log(auditionResult)

  return (  
    <Fragment>
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row feed-body">
              <div className="col-xl-8 col-xxl-9 col-lg-8">
          
                  <div className="card d-block border-0 shadow-000 rounded-xxxl overflow-hidden cursor-pointer mb-3 mt-0 me-3">
                    
                    <div className="card d-block border-1 shadow-000 d-flex rounded-3 2">
                    <Suspense fallback={<Load />}>
                    <Flash />
                    </Suspense>
                         <div className="d-flex justify-content-center  w-600x overflow-hidden pt-1">
                        
                        {/* <Marquee pauseOnHover="pause" className="font-xs text-white" direction="left"> */}
                        <Suspense fallback={<Load />}>
                        <Hallfame></Hallfame>
                        </Suspense>                         
                          {/* </Marquee> */}
                          </div>
                          <Appfooter></Appfooter>
                       
                        <div className="card d-block border-0 shadow-000 rounded-xxxl bg-gradiant-bottom overflow-hidden pt-1">
                          <img className="rounded-3 w-100" src= {cdn+"home/task.gif"}></img>
                        </div>   
                    <div>
                  
                    </div>
                        <div>
                     {bday?.length > 0 &&
                    bday.map((item, index) => {     
                      //console.log(item);
                      return (
                      
                        <React.Fragment key={index}>
                        
                          <Suspense fallback={<Load />}>
                          <Postview
   
                            id={item.id}
                           
                            postimage={item.mediaType== 'img' ? item?.mediaUrl:'' }
                            postvideo={item.mediaType== 'video' ? (item.status==2?ssd+item.mediaUrl:cdn+'users/'+item.mediaUrl):'' }
                            avater={item.picture?(item.picture.includes('googleusercontent')?item.picture:cdn+'users/'+item.picture):""}
                            user={item.name?item.name:''}
                            time={item.createdAt}
                            //des={item?.text?highlightPattern(item.text,'\#(.*?)\#'):item?.text}
                            des = {item.text}
                            item={item}
                            setGift={sendGift}
                            giftItem={giftItem}
                            
                          />
                          </Suspense>
                        
                        </React.Fragment>
                      );
                    })
                    }
                     </div>
                        <div    className="card d-block border-0  rounded-xxxl pt-1">      
               
                     <div id='survey'>
                     <ChatDemo></ChatDemo>
                     </div>
  
                     
                </div>
               
     
               
         
          </div>
       
                </div>

             
             
              </div>

          </div> 
          
            </div>
            
          </div>
            <Moments sponsor={true}/>
        </div> 
       
       
    </Fragment>
  );
}
