import React, { useContext,useState,useEffect } from 'react';
import { AuthContext } from '../context/authContext';
import profileService from "../services/profile.service";
import { useNavigate } from "react-router-dom";

export default function ChatInbox ({ title }) {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [ib, setIB] = useState(null);
  const cdn = 'https://files.geetsuhane.com/';
  
  const getIB = async () => {
    var ibcount = await profileService.getResult({
        qry: `CALL sp_getChatInbox('${currentUser?.id}');`
    })
      setIB(ibcount?.[0]);
      //console.log(ibcount?.[0]);
}
//console.log(currentUser)
  const recirect2Chat = (chatid,chatname,pic) => {
    if(chatid=='Planet')  {    
          console.log(chatname);
            if(chatname==='GupShup') {
              navigate('/gupshup');
              return;
            } else {
              navigate('/gupshup?groupChat='+chatname);
              return;
            }
    } else {
      localStorage.setItem("ibuser",chatname);
      localStorage.setItem("ibpic",pic);
      localStorage.setItem("ibid",chatid);
      navigate('/chat?ibuser='+chatname+'&ibpic='+pic+'&ibid='+chatid);
    }
    
  }
  useEffect(() => {
      async function fetchList() {      
        await getIB();      
      } 
      fetchList();
  },[])

  return (
    <div
      id='main-content-wrap'
      className={`right-chat nav-wrap mt-0 right-scroll-bar active-sidebar`}
    >
      <div className='middle-sidebar-right-content bg-white shadow-xss rounded-sm'>
        <div className='section full pt-4 mt-4 position-relative feed-body'>
          <h4 className='font-xsssss text-grey-500 text-uppercase fw-700 px-2 ls-3'>
            {/* {title || 'CONTACTS'} */}Chat
          </h4>
          <ul className='list-group list-group-flush'>
            {ib?.length
              ? ib.map((value, index) =>
                 
                    <li
                      onClick={e => {
                        recirect2Chat(value?.senderId==='Planet'?'Planet':value?.SID,value.name,value?.picture);
                      }}
                      key={index}
                      className={`${
                        
                          'bg-white rounded-{index} '
                          
                      } list-group-item no-icon px-2 pt-2 pb-2 border-0 d-flex align-items-center cursor-pointer`}
                    >
                      <figure className='avatar float-left mb-0 me-0'>
                        {value?.picture ? (
                          <img
                            className='rounded-img-sm mx-0'
                            src={value?.senderId==='Planet'?cdn+value?.picture:cdn+'users/'+value.picture}
                            alt=''
                          />
                        ) : (
                          <div className='align-items-center bg-grey d-flex justify-content-center rounded-img-sm'>
                            <span className='ti-user font-sm'></span>
                          </div>
                        )}
                      </figure>
                      <h3 className='fw-700 mb-0 mt-0 '>
                        <span className='font-xssss text-grey-600 d-block text-dark pointer'>
                   
                          {value.name}  <span className={`${value?.ibcount>0?'circle-count bg-warning mt-2 text-white p-1':''}`}>{value?.ibcount>0?value.ibcount:''}</span>
                        </span>
                       
                      </h3>
                      {/* <span>{value.isOnline ? 'online' : 'offline'}</span> */}
                    </li>
                  
                )
              : ''}
              {currentUser.lead>0 &&
              <li
                      onClick={e => {
                        recirect2Chat('Planet','leads','groups/gold.png');
                      }}
                   
                      className={`${
                        
                          'bg-white rounded-{index} '
                          
                      } list-group-item no-icon px-2 pt-2 pb-2 border-0 d-flex align-items-center cursor-pointer`}
                    >
                      <figure className='avatar float-left mb-0 me-0'>
                        
                          <img
                            className='rounded-img-sm mx-0'
                            src={cdn+'groups/gold.png'}
                            alt='Leads'
                          />
                       
                      </figure>
                      <h3 className='fw-700 mb-0 mt-0 '>
                        <span className='font-xssss text-grey-600 d-block text-dark pointer'>
                   
                          Leads  
                        </span>
                       
                      </h3>
                      {/* <span>{value.isOnline ? 'online' : 'offline'}</span> */}
                    </li>
          }
          </ul>
        </div>
      </div>
    </div>
  )
}
