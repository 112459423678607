import React, { Fragment, useContext, useEffect, useState, useRef,useMemo } from "react";
//import { io } from "socket.io-client"; // Add this to connect with the socket server
import { createSocket } from '../socket';  // Import the `createSocket` function
import alertService from '../services/sweetAlert';
import { AuthContext } from "../context/authContext";
import { Link, useNavigate } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import useSound from 'use-sound';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Ratings from "./Ratings";
import { NotificationContext } from "../context/notificationsContext";
import profileService from "../services/profile.service";
const endpoint = process.env.REACT_APP_API_END_POINT;
//const socket = io(endpoint);
//const socket = createSocket();

export default function Cafe() {
    const { currentUser, getUserDetails } = useContext(AuthContext);
    const { sendMessageToGroup, activity } = useContext(NotificationContext);
    const navigate = useNavigate();
    const cdn = 'https://files.geetsuhane.com/';
    const [loading, setLoading] = useState(false);
    const [atmPlayers, setAtmPlayers] = useState({}); // Use an object to track players by userId
    const [ticker, setTicker] = useState(false);
    const [animate, setAnimate] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [gscStar, setGSCStar] = useState(null);
    const [looteras, setLooteras] = useState(null);
    const [claimValue, setClaimValue] = useState(0);
    const [looto, setLooto] = useState(false); // State to control animation
    const [lootera, setLootera] = useState(''); // Image of the lootera
    const [bechara, setBechara] = useState(''); // Image of the victim    
    const [busAnimate, setBusAnimate] = useState(null);
    const [busEnterAnimate, setBusEnterAnimate] = useState(null);
    const withdrawRef = useRef(null);
    const busRef = useRef(null);
    const [joined, setJoined] = useState(false);
    const [socket, setSocket] = useState(null);

    //const [playCafe] = useSound(cdn + 'sound/labpe.mp3');
    const [playBus] = useSound(cdn + 'sound/bus.mp3');
    const [playCoins] = useSound(cdn + 'sound/cash.mp3');
    const [playCops] = useSound(cdn + 'sound/cops.mp3');
    const [playThief] = useSound(cdn + 'sound/thief.mp3');
    const [playLose] = useSound(cdn + 'sound/lose.mp3');

    const sendMessage = async (msg) => {
        sendMessageToGroup({
            message: msg,
            senderId: currentUser.id,
            name: currentUser.name,
            picture: currentUser.picture,
            receiverId: 2,
            groupName: 'Activity',
            time: new Date(),
            msgType: 'text',
            sender: currentUser
        });
    };

    // useEffect(() => {
    //     const newSocket = createSocket();  // Ensure this only runs once
    //     setSocket(newSocket);
      
    //     return () => {
    //       newSocket.disconnect();  // Clean up when the component unmounts
    //     };
    //   }, []);  // Empty dependency array ensures it only runs once

      
  // UseSpring hook for loot animation
    const looting = useSpring({
        from: { transform: "translate(0px, 0px) scale(1) rotate(0deg)" },
        to: {
            transform: looto 
                ? "translate(325px, -500px) scale(1.2) rotate(720deg)" 
                : "translate(0px, 0px) scale(1) rotate(0deg)"
        },
        config: { duration: 500, easing: t => t * t * (3 - 2 * t) }, // Smooth easing
        onRest: () => setLooto(false) // Stop the animation after one loop
    });

    const getGSC = async (p) => {
        const gsc = await profileService.getResult({
          qry: `CALL sp_getGSC('${currentUser?.id}',${p})`
      }).then(r=> {
        setGSCStar(r?.[0]);
      });
      
      }

    // Socket Emitters for Server Events
    const fetchAtmPlayers = () => {
        if(socket)
        socket.emit('get_recent_with_points');
    };

    const fetchLooters = () => {
        if(socket)
        socket.emit('get_last_five_looters');
    };

    const sendAtmProcess = (claimType, poor ) => {
        const points = claimType === 1 ? 50 : 20; // Assign points based on claim type (just an example)
    
        socket.emit('atm_process', {
            userId: currentUser?.SID,
            claimType,
            points: points, // Make sure to send the correct points here
            poor,
            picture: currentUser?.picture
        });
    };
    
    

    const copsArrive = () => {
        if(socket)
        socket.emit('cops_arrive');
    };

    const takeBus = () => {
        socket.emit('take_bus', { userId: currentUser?.SID });
    };

    // Socket Listeners for Server Events
    useEffect(() => {
        const newSocket = createSocket();  // Ensure this only runs once
        setSocket(newSocket);

        async function fetchList() {
            if (!loading) {
                newSocket.emit('get_recent_with_points');
                newSocket.emit('get_last_five_looters');
                await getGSC(0);
                }
            }

        newSocket.on('recent_with_points', (data) => {
            const { players, looteras } = data;
        
            //console.log('Players received:', players);
            //console.log('Looteras received:', looteras);
        
            setAtmPlayers(players);  // Update players array in state
            setLooteras(looteras);    // Update looteras array in state
        });
        
        newSocket.on('cops_catch', (message) => {
            alertService.info('Cops Alert', message);
        });
     // Listen for loot messages for the lootera
     newSocket.on('loot_message', (data) => {
            alertService.success('Loot Notification', data.message);
            // Add animation for lootera (optional)
            setLootera(data.looteraImage);
            setBechara(data.victimImage);
            setLooto(true);
        });

        // Listen for notification sent to the victim
        newSocket.on('loot_notification', (message) => {
            //console.log('Loot notification received:', message); // Debug log
            playThief();
            alertService.info('Loot Alert', message);
        });
        // Bus credit message
        newSocket.on('bus_credit', (data) => {
            if (data && data.points !== undefined) {
                alertService.success('Bus', `${data.points} points credited to your account!`);
            } else {
                alertService.error('Bus', 'Something went wrong while crediting points.');
            }
             getGSC(data.points);
        });
       
        newSocket.emit("join", {
            name: currentUser?.name,
            id: currentUser?.SID,
            picture: currentUser?.picture
        });
            const events = [
                'recent_with_points',
                'cops_catch',
                'loot_message',
                'loot_notification',
                'bus_credit'
            ];
             fetchList();
            return () => {
                events.forEach(event => newSocket.off(event));
                newSocket.disconnect();
            };
            
    }, [currentUser]);
  

    const cops = useSpring({
        from: { transform: "translate(0px,150px)" },
        to: [{ transform: animate ? " translate(25px,150px)" : "translate(0px,150px)" },
        { transform: animate ? " translate(27px,148px)" : "translate(0px,150px)" }],
    });


    const bus = useSpring({
        from: { transform: "translate(0px,200px)" },
        to: [{ transform: busAnimate ? " translate(125px,200px)" : "translate(0px,200px)" }],
        config: { duration: 500 },
    });

    const bus_enter = useSpring({
        from: { transform: "translate(0px,200px)" },
        to: [{ transform: busAnimate ? " translate(25px,200px)" : "translate(0px,200px)" },
        { transform: busAnimate ? " translate(27px,200px)" : "translate(0px,200px)" }],
    });

    const resetBet = async () => {
        const t = new Date().getSeconds();

        setTicker(t);
        if (t === 55) playCops();
        if (t === 59) {
            setAnimate(true);
            fetchLooters();
            //if (claimValue > 0) {
                copsArrive();
                //setClaimValue(0);
            //}
        } else if (t===2 || t===10 || t === 20 || t === 30 || t===40 || t===50) {
            playBus();
            setBusAnimate(true);
        } else if (t === 3 || t === 12 || t===22 || t=== 32 || t===42 || t===52) {
            playBus();
            setBusEnterAnimate(true);
        } else if (t === 5 || t === 14 || t===24 || t===34 || t===44 || t===54) {
            setBusEnterAnimate(false);
            setBusAnimate(false);
        }
        if (t === 1) {
            setAnimate(false);
        }
    }

    const atmProcess = (value, claimType) => {
        //playCafe();
        let poor = '';
    
        if (claimType === 2) {
            if (value?.sid === currentUser?.SID) {
                alertService.error('Error', `You can't loot yourself :)`);
                //playThief();
                return;
            }
            playThief();
            setBechara(value?.picture?.includes('googleusercontent') ? value.picture : cdn + 'users/' + value.picture);
            setLootera(value?.picture?.includes('googleusercontent') ? value.picture : cdn + 'users/' + currentUser?.picture);
            //setLooto(true);
            poor = value?.sid;
    
            setClaimValue(prevValue => prevValue + 20); // Add 20 points for looting
        } else if (claimType === 1) {
            alertService.info('Withdraw', '50 points will be credited if you take the bus!');
            withdrawRef.current.disabled = true;
    
            setClaimValue(prevValue => prevValue + 50); // Add 50 points for withdrawing
        }
    
        sendAtmProcess(claimType, poor);
    };
    
    
    
    

    useEffect(() => {
        // async function fetchList() {
        //     if (!loading) {
        //         fetchAtmPlayers();
        //         fetchLooters();
        //         await getGSC(0);

        //     }
            const reset = setInterval(() => {
                resetBet()
            }, 1000);
        //}
        //fetchList();
    }, [currentUser]);



    return (
        <Fragment>
            <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <center>
                                <div className="pt-2">
                                    <div className="bg-secondary text-white font-xssss p-1"> GS Cafe Game <Ratings uid="gsc" type="game" size="small"></Ratings></div>
                                    <div className="font-xsssss">Top GSC Stars may win weekly/monthly e-gifts</div>
                                    <div style={{ bottom: "0px", top: "300px", alignItems: "left" }}>
                                        <span className="p-1 text-green font-xsssss b-0 "> Players:
                                     
                                            {atmPlayers?.length && atmPlayers?.map((value, index) => (
                                                <img key={index} onClick={() => atmProcess(value, 2)}
                                                    className="rounded-img-sm mx-1 pointer"
                                                    src={value?.picture?.includes('googleusercontent') ? value.picture : cdn + 'users/' + value.picture}
                                                    alt={value?.name}
                                                />
                                            )) }
                                        </span>
                                    </div>
                                    <div style={{ alignContent: "left" }} className="p-1 ">
                                        <span className="text-red font-xsssss"> Looteras:
                                            {looteras?.length && looteras?.map((value, index) => (
                                                <img key={index} onClick={() => atmProcess(value, 2)}
                                                    className="rounded-img-sm mx-1 pointer"
                                                    src={value?.picture?.includes('googleusercontent') ? value?.picture : cdn + 'users/' + value?.picture}
                                                    alt={value?.name}
                                                />
                                            )) }
                                        </span>
                                    </div>
                                    <div style={{
                                        position: "relative",
                                        backgroundImage: `url("${cdn}home/gscafe.gif")`,
                                        width: "100%",
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center",
                                        height: "425px"
                                    }}>
                                       <div>
                                            <button
                                                onClick={() => atmProcess('', 1)} // Call atmProcess with claimType = 1 for withdraw
                                                ref={withdrawRef}
                                                disabled={busAnimate || animate ? "disabled" : ""}
                                                style={{ position: "relative", top: "200px", left: "0px", fontSize: "10px", float: "left", padding: "2px" }}
                                            >
                                                Withdraw
                                            </button>
                                        </div>
                                     
                                        <animated.div style={bus}>
                                            {busAnimate && !busEnterAnimate && (
                                                <div style={{
                                                    position: "relative",
                                                    backgroundImage: `url("${cdn}home/bus.png")`,
                                                    width: "100%",
                                                    backgroundSize: "cover",
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundPosition: "center",
                                                    height: "225px"
                                                }}>
                                                </div>
                                            )}
                                        </animated.div>
                                        <animated.div style={looting}>
                                            <img style={{ visibility: looto ? 'visible' : 'hidden' }} className="rounded-img-sm mx-1 pointer" 
                                            src={lootera?.includes('googleusercontent') ? lootera : cdn + 'users/' + lootera} />
                                            <img style={{ visibility: looto ? 'visible' : 'hidden' }}  className="rounded-img-sm mx-1 pointer" 
                                            src={bechara?.includes('googleusercontent') ? bechara : cdn + 'users/' + bechara} />

                                        </animated.div>
                                        <animated.div style={bus_enter}>
                                            {busEnterAnimate && (
                                                <div style={{
                                                    position: "relative",
                                                    backgroundImage: `url("${cdn}home/busopen.png")`,
                                                    width: "100%",
                                                    backgroundSize: "cover",
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundPosition: "center",
                                                    height: "225px"
                                                }}>
                                           <button
                                                ref={busRef}
                                                onClick={() => {
                                                    takeBus(); // Trigger the bus event
                                                    busRef.current.disabled = true; // Disable the button after clicking
                                                    setClaimValue(0); // Reset claim value after points are credited
                                                }}
                                                style={{ position: "relative", top: "150px", left: "-50px", fontSize: "10px", padding: "2px" }}
                                            >
                                                Take Bus
                                            </button>


                                                </div>
                                            )}
                                        </animated.div>
                                        {animate && (
                                            <animated.div style={cops}>
                                                <div style={{
                                                    position: "relative",
                                                    backgroundImage: `url("${cdn}home/cops.png")`,
                                                    width: "100%",
                                                    backgroundSize: "cover",
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundPosition: "center",
                                                    height: "425px"
                                                }}>
                                                </div>
                                            </animated.div>
                                        )}
                                 
                                    </div>
                                    <div className="pt-15 text-center"> 
                                        <center>
                                            {gscStar &&
                                                <div className="card-body  d-flex position-relative ">
                                                    {gscStar?.map((value, index) => (
                                                        <div style={{ maxWidth: '170px' }} key={index} className="col-sm-5 pe-2 ps-1 ">
                                                            <Link to={`/userpage?uid=${value ? value.SID : ''}`}>
                                                                <figure className="avatar me-1">
                                                                    {value?.picture ? (
                                                                        <img
                                                                            className='rounded-img-sm mx-1'
                                                                            src={
                                                                                value?.picture.includes('googleusercontent') ? value.picture : value.picture.includes('groups/') ? cdn + value.picture :
                                                                                    cdn + "users/" +
                                                                                    value.picture
                                                                            }
                                                                            alt=''
                                                                        />
                                                                    ) : (
                                                                        <div className='align-items-center bg-grey d-flex justify-content-center rounded-img-sm mx-1'>
                                                                            <span className='ti-user font-sm'></span>
                                                                        </div>
                                                                    )}
                                                                </figure>
                                                            </Link>
                                                            <h4 className="font-xsssss"><span className="strong ">{value?.name}</span>
                                                                <div> <Ratings uid={value?.SID} size="small"></Ratings></div>
                                                                <div className='message-wrap justify-content-center text-red align-items-center mt-0 pt-0 '>
                                                                    <img src="https://files.geetsuhane.com/home/points.png" alt="points" className="w10 rounded-3 " /> {value?.worth}
                                                                </div>
                                                                <div className='message-wrap justify-content-center text-orange align-items-center mt-0 pt-0 font-xssss'>
                                                                    GSC Star
                                                                </div>
                                                                <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                                                                    <Link to={`/search?groupDetail=${value?.gname}`} className="bg-secondary pointer p-1 font-xssss text-white">
                                                                        <i className="feather-globe btn-round-xssss bg-primary-gradiant me-1"></i>{value ? value?.gname : ''}  </Link>
                                                                </span>
                                                            </h4>
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                        </center>
                                    </div>
                                </div>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
