import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../context/authContext";
import profileService from "../services/profile.service";
import useSound from 'use-sound';
import { Avatar, Button } from "@mui/material";
import alertService from "../services/sweetAlert";
import './TeamSelection.css';
import { Link } from "react-router-dom";


const TeamSelection = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [winner, setWinner] = useState("");
  const cdn = 'https://files.geetsuhane.com/';
  const [playJoin] = useSound(cdn + 'sound/boat.mp3',{loop: true});
  const [playLose] = useSound(cdn + 'sound/lose.mp3');
  const [teams, setTeams] = useState([]);
  const [food, setFood] = useState(null);
  const [timeLeft, setTimeLeft] = useState("");
  const avatars = document.querySelectorAll('.avatar');


  const getTeams = async () => {
    playJoin();
    var res = await profileService.getResult({
      qry: `CALL sp_getBoatRaceTeams('')`
    }).then(r => {
      // console.log(r?.[0])
     setTeams(r?.[0])
    });
  };
  const getWinners = async () => {
    var res = await profileService.getResult({
      qry: `CALL sp_getBoatRaceWinners()`
    }).then(r => {
      //console.log(r?.[0]?.length)
       if(r?.[0]?.length>0)
      setWinner(r?.[0][0]?.pic)
    });
  };

  const fetchFood =async () => {
    var res = await profileService.getResult({
      qry: `CALL sp_food('','${currentUser?.gname}','${currentUser?.id}')`
    }).then(r => {
      //console.log(r?.[0][0])
      if(r?.[0]?.length>0) {
        if(r?.[0][0]?.value==='Breakfast') {
          setFood('boatrace/bf.gif');
        }else if(r?.[0][0]?.value==='Lunch') {
          setFood('boatrace/l1.gif');
        }else if(r?.[0][0]?.value==='Snacks') {
          setFood('boatrace/s1.gif');
        }else if(r?.[0][0]?.value==='Dinner') {
          setFood('boatrace/d1.gif');
        } else {
          setFood('');
        }
      }
     

    
    });

  }

  const handleGrabClick = async () => {
    var res = await profileService.getResult({
      qry: `CALL sp_food('grab','${currentUser?.gname}','${currentUser?.id}')`
    }).then(r => {
      console.log(r?.[0][0]?.res)
       alertService.info('Food', r?.[0][0]?.res);

     });
  }

  const calculateTimeLeft = () => {
    const now = new Date();
    const utc11pm = new Date(now);

    // Set to 11 PM UTC today
    utc11pm.setUTCHours(16, 0, 0, 0); 

    // If the current time has already passed 11 PM UTC, set the race start time to the next day
    if (now > utc11pm) {
        utc11pm.setUTCDate(utc11pm.getUTCDate() + 1); // Move to the next day
    }

    const difference = utc11pm - now;
    let timeLeft = "";

    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    timeLeft = `${hours}h ${minutes}m ${seconds}s`;

    setTimeLeft(timeLeft);
};


  useEffect(() => {
    async function fetchData() {
     await getTeams();
     await fetchFood ('');
     await getWinners();
      calculateTimeLeft();
    }
    const timer = setInterval(calculateTimeLeft, 1000);
    fetchData();
    return () => clearInterval(timer); // Clear the interval on component unmount
  }, [currentUser]);

  return (
<div className="team-selection-container">
  <div className="top-controls">
    <div className="race-start-info strong">
      Race starts in: {timeLeft}
    </div>
  </div>
  <div className="team-selection">

  {food?.length>0 &&  winner?.length===0 && (
      <div className="food-container">
       
        <img src={cdn + food} alt="Food" className="food-image" />
        <button className="grab-button" onClick={handleGrabClick}>Grab</button>
      </div>
    )}
    {winner?.length>0  && (
      <div className="food-container">
        <Avatar sx={{ width: 300, height: 300 }} src={cdn + winner}></Avatar>
        <div className="grab-button" >Congratulations!!</div>
      </div>
    )}
    
   {/* { winner?.length===0 &&
   <div class="race-container">
  <div > <img class="avatar1 avatar-1" src="https://files.geetsuhane.com/users/4b00800a-c0d3-4d00-8cd0-5c044e79fb6f/profile/Screenshot_20240810-194426_Gallery.jpg"></img> </div>
  <div ><img class="avatar1 avatar-2" src="https://files.geetsuhane.com/users/b2db82cd-0737-45f6-969e-ec9918928f52/profile/IMG-20181021-WA0013.jpg"></img></div>
  <div ><img class="avatar1 avatar-3" src="https://files.geetsuhane.com/users/46e11e7d-3876-4021-94fc-7cf85fecc6c5/profile/IMG_20240914_064743.jpg"></img></div>
  <div > <img class="avatar1 avatar-4" src="https://files.geetsuhane.com/users/31a60689-3044-4ed5-9f61-cae89ff6abaa/profile/1688317376063.jpg"></img> </div>
  <div ><img class="avatar1 avatar-5" src="https://files.geetsuhane.com/users/95a49e83-d8ef-4e6b-8f05-6bbc2b18232a/profile/Screenshot_20240704-222625_Facebook.jpg"></img></div>
  <div ><img class="avatar1 avatar-6" src="https://files.geetsuhane.com/users/11c48538-68ba-4c60-b846-f24b53eabf22/profile/IMG_20240710_233020.jpg"></img></div>
 
</div>
  } */}


    {teams?.length > 0 && food?.length===0 && teams?.map((team) => (
      <a key={team?.id} href={`/search?searchBy=race&team=${team?.name}`}>
        <div className={`team-card`}>
          <div className="row">
            <div className="font-xssss text-red text-left counter feather-zap strong">
              {team?.points}
            </div>
          </div>
          <div className="text-center">
            <center>
              <Avatar sx={{ width: 75, height: 75 }} src={cdn + team?.pic}></Avatar>
            </center>
          </div>
        </div>
      </a>
    ))}
  </div>
</div>

  );
};

export default TeamSelection;
