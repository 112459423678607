import React, { useState, useEffect, useContext } from 'react';
//import io from 'socket.io-client';
import { createSocket } from '../socket';  // Import the `createSocket` function
import './BoatRace.css';
import TeamSelection from './TeamSelection';
import profileService from '../services/profile.service';
import alertService from "../services/sweetAlert";
import { AuthContext } from '../context/authContext';
import { Avatar, Button } from "@mui/material";
import useSound from 'use-sound';


const endpoint = process.env.REACT_APP_API_END_POINT;
//const socket = io(endpoint);
//const socket = createSocket();

const BoatRace = () => {
  const [boats, setBoats] = useState([]);
  const [isOverlayVisible, setIsOverlayVisible] = useState(true);
  const { currentUser } = useContext(AuthContext);
  const [qual, setQual] = useState(0);
  const [raceTimeLeft, setRaceTimeLeft] = useState("");
  const cdn = 'https://files.geetsuhane.com/';
  const [socket, setSocket] = useState(null);
  const [playJoin] = useSound(cdn + 'sound/boat.mp3',{loop: true});
  const [playDhol] = useSound(cdn + 'sound/dhol.mp3');
  const [playZoom] = useSound(cdn + 'sound/speed.mp3');
  const [playChuckle] = useSound(cdn + 'sound/chuckle.mp3');

  const [lastTapTime, setLastTapTime] = useState(0);
  //const [lvl,setlvl] =useState(1);
  const [hits,setHits] =useState(0);
  const [rank,setRank] =useState(0);
  const [tool,setTool] =useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [animateValue, setAnimateValue] = useState(null); // To handle the animated value when clicked
  const [shuffledImages, setShuffledImages] = useState([]);
  const [spinningImage, setSpinningImage] = useState(null); // Image that will spin and disappear

  const [visiblePositions, setVisiblePositions] = useState({
    leftCenter: false,
    middleCenter: false,
    rightCenter: false,
    rightBottom: false
  });


  const images = [
    { src: "https://files.geetsuhane.com/boatrace/m02.gif", value: 2 },
    { src: "https://files.geetsuhane.com/boatrace/m03.gif", value: 3 },
    { src: "https://files.geetsuhane.com/boatrace/m04.gif", value: 4 },
    { src: "https://files.geetsuhane.com/boatrace/m05.gif", value: 5 }
  ];

  const positions = ['leftCenter', 'middleCenter', 'rightCenter', 'rightBottom'];

  // Shuffle the image array
  const shuffleArray = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };
  let lastClickTime = 0;
  const clickCooldown = 6000; // 300ms cooldown between clicks
  

  // Function to toggle positions and shuffle images
  useEffect(() => {
    const shuffleArray = (array) => {
      return array.sort(() => Math.random() - 0.5);
    };
  
    const intervalId = setInterval(() => {
      // Reset all positions to false
      setVisiblePositions({
        leftCenter: false,
        middleCenter: false,
        rightCenter: false,
        rightBottom: false
      });
  
      // Shuffle images every interval and store in state
      const shuffledImagesArray = shuffleArray([...images]);
      setShuffledImages(shuffledImagesArray);
  
      // Shuffle positions as well to randomize their order
      const shuffledPositions = shuffleArray([...positions]);
  
      // Pick a random position to be visible
      const randomPosition = shuffledPositions[0]; // Pick the first shuffled position
  
      setVisiblePositions((prevState) => ({
        ...prevState,
        [randomPosition]: true
      }));
  
      // Move to the next image and position
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % shuffledPositions.length);
    }, 5000); // Change every 3 seconds
  
    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [currentImageIndex]);
  

  // Handle image click to setTool, animate value, show spinning, and hide image
  const handleImageClick = (index) => {
    const currentTime = Date.now();
  if (currentTime - lastTapTime < 600) {
    alertService.error("Clicking too fast! Slow down.");
    return; // Ignore this click if too fast;
  }
  setLastTapTime(currentTime);

    if (!Object.values(visiblePositions)[index]) return;
    playChuckle();
    const tval = shuffledImages[index].value;
    setTool(tool+shuffledImages[index].value);

    // Animate score or value based on the clicked image
    setAnimateValue(`X${tval} Times`);

    // Show spinning effect
    setSpinningImage(index);

    // Remove spinning and animated value after a timeout
    setTimeout(() => {
      setAnimateValue(null);
      setSpinningImage(null); // Reset spinning image
      setVisiblePositions({
        leftCenter: false,
        middleCenter: false,
        rightCenter: false,
        rightBottom: false
      });
    }, 1000); // Disappear after 1 second
  };



  const [playExplode] = useSound(cdn + 'sound/explosion.mp3');

  const getBoats = async () => {
    playDhol();
    playJoin();
    try {
      const res = await profileService.getResult({
        qry: `CALL sp_getBoatRaceTeams('score')`
      });
      setBoats(res?.[0]);
      //console.log(res);
    } catch (error) {
      console.error("Error fetching boats:", error);
    }
  };

  const checkQual = async () => {
    playDhol();
    playJoin();
    try {
      const res = await profileService.getResult({
        qry: `CALL sp_boatRace('','check','${currentUser?.gname}',0)`
      }).then(r=> {
          console.log(r?.[0][0]?.res);
          setQual(r?.[0][0]?.res);
      });     
    } catch (error) {
      console.error("Error fetching qual:", error);
    }
  };

  useEffect(() => {
    checkQual();
  }, [currentUser]); // Empty dependency array ensures it runs on mount

  const checkOverlayVisibility = () => {
    const now = new Date();
    const raceStartTime = new Date(now);
    raceStartTime.setUTCHours(16, 0, 0, 0); // 11 PM UTC for testing
    const raceEndTime = new Date(raceStartTime.getTime() + 30 * 60 * 1000); // 11:30 PM UTC

    if (now >= raceStartTime && now <= raceEndTime) {
      setIsOverlayVisible(false); // Hide overlay during race time
      startRaceTimer(raceEndTime - now); // Start the race timer
      
    } else {
      setIsOverlayVisible(true); // Show overlay outside race time
      setRaceTimeLeft(""); // Reset race time when race is not ongoing
    }
  };

  const startRaceTimer = (initialTime) => {
    let raceEndTime = initialTime;

    const raceTimer = setInterval(() => {
      raceEndTime -= 1000;
      const minutes = Math.floor(raceEndTime / (1000 * 60));
      const seconds = Math.floor((raceEndTime % (1000 * 60)) / 1000);

      setRaceTimeLeft(`${minutes}m ${seconds}s`);

      if (raceEndTime <= 0) {
        clearInterval(raceTimer);
        setIsOverlayVisible(true); // Show overlay after the race ends
        setRaceTimeLeft(""); // Clear the timer when the race ends
      }
    }, 1000);
  };


  const handleTap = async (boatId,lvl) => {
    if(hits>=500) {
      alertService.error('Max hits reached', `You have already used allowed max 1000 hits`);
      return;
    }
 
    const currentTime = Date.now();
    if (currentTime - lastTapTime < 1000) {
      return;
    }
    setLastTapTime(currentTime);
    let incrementValue = tool;
  
    if (rank<11) {
      //console.log('rank'+rank)
      incrementValue =Math.round( tool*100*(lvl>0?lvl:1)/(rank>0?rank:10));

      console.log('incrementValue'+incrementValue)
    }

    if(incrementValue===0) {
      alertService.error('Tools', `You have 0 tools, please collect from mermaids`);
      return;
    } else {
      setTool(0);
    }
   
    if(!currentUser) return;
  
    // Immediately trigger animations
    if (currentUser?.gname === boatId || qual===0) {
      playZoom();
      //console.log(incrementValue)
      animateScoreChange(boatId, incrementValue);
      if(socket)
      socket.emit('updateScore', { playerId: currentUser?.name, boatId: boatId, action: 'accelerate', score: incrementValue,id:currentUser?.SID,planet: currentUser?.gname });
    } else {
      const decrementValue = -incrementValue;
      //console.log('decrementValue'+decrementValue)

      animateScoreChange(boatId, decrementValue);
      triggerExplosion(boatId, decrementValue);
      if(socket)
      socket.emit('updateScore', { playerId: currentUser?.name, boatId: boatId, action: 'destroy', score: decrementValue,id:currentUser?.SID,planet: currentUser?.gname  });
    }
  };
  


  const animateScoreChange = (boatId, change) => {
    setBoats((prevBoats) => {
      const boatIndex = prevBoats.findIndex((boat) => boat.team === boatId);
      if (boatIndex !== -1) {
        const updatedBoats = [...prevBoats];
        // Ensure the score is treated as a number
        updatedBoats[boatIndex] = {
          ...updatedBoats[boatIndex],
          score: (parseInt(updatedBoats[boatIndex].score, 10) || 0) + change,
        };
        return updatedBoats;
      }
      return prevBoats;
    });
  
    const scoreElement = document.createElement('div');
    scoreElement.className = 'score-change';
    scoreElement.innerText = change > 0 ? `+${change}` : `${change}`;
    const boatElement = document.getElementById(`boat-${boatId}`);
    boatElement.appendChild(scoreElement);
  
    setTimeout(() => {
      boatElement.removeChild(scoreElement);
    }, 1000);
  };
  
  

  const triggerExplosion = (boatId, change) => {
    const boatElement = document.getElementById(`boat-${boatId}`);
    playExplode();
    if (boatElement) {
      // Create an explosion element
      const explosionElement = document.createElement('div');
      explosionElement.className = 'explosion-effect';
      const explosionImage = document.createElement('img');
      explosionImage.src = 'https://files.geetsuhane.com/boatrace/explosion.gif'; // Adjust the path to your explosion image
      explosionImage.alt = 'Explosion';
      explosionElement.appendChild(explosionImage);

      // Create a score change element
      const scoreElement = document.createElement('div');
      scoreElement.className = 'score-change';
      scoreElement.innerText = change > 0 ? `+${change}` : `${change}`;
      scoreElement.style.color = 'red'; // Always red for explosion
      boatElement.appendChild(scoreElement);
  
      // Append the explosion to the boat element
      boatElement.appendChild(explosionElement);
  
      // Remove the explosion effect and score change after 0.5 second
      setTimeout(() => {
        boatElement.removeChild(explosionElement);
        boatElement.removeChild(scoreElement);
      }, 500);
    }
  };
  
  // const triggerAvatarAnimation = (playerId, boatId, action) => {
  //   if(action==='destroy') playExplode();
  //   const boatElement = document.getElementById(`boat-${boatId}`);
  //   if (boatElement) {
  //     // Create an avatar animation element
  //     const avatarElement = document.createElement('div');
  //     avatarElement.className = `avatar-animation ${action}`;
  //     avatarElement.innerText = action === 'accelerate' ?  `${playerId}` :  `${playerId}`;
  //     boatElement.appendChild(avatarElement);
  
  //     // Add a CSS class for animation
  //     avatarElement.classList.add('animate');
  
  //     // Remove the animation element after it finishes
  //     setTimeout(() => {
  //       boatElement.removeChild(avatarElement);
  //     }, 1000); // Adjust timing as needed
  //   }
  // };

  const triggerAvatarAnimation = (playerId, boatId, action) => {
    if (action === 'destroy') playExplode();

    // Create an avatar animation element
    const avatarElement = document.createElement('div');
    avatarElement.className = `avatar-animation ${action}`;
    avatarElement.innerText = playerId;

    // Append to the body so it's centered on the page, not attached to the boat
    document.body.appendChild(avatarElement);

    // Add a CSS class for animation and background effect
    avatarElement.classList.add('animate');

    // Remove the animation element after it finishes
    setTimeout(() => {
        document.body.removeChild(avatarElement);
    }, 1500); // Adjust timing as needed
};

  

  useEffect(() => {
    const newSocket = createSocket();  // Ensure this only runs once
    setSocket(newSocket);
  
      async function fetchData() {
      await getBoats();
      //await checkQual();
      checkOverlayVisibility();
      }

    const interval = setInterval(() => {
      checkOverlayVisibility();
    }, 1000); // Re-check every second
    
    newSocket.on('updateBoats', (updatedBoats) => {
      if(updatedBoats?.length>0)
      setBoats(updatedBoats); // Update the local state with the server-synced data
      console.log(updatedBoats)
    });
    // Listen for avatar animation events from the server
    newSocket.on('animateAvatar', ({ playerId, boatId, action }) => {
      // console.log(playerId);
      triggerAvatarAnimation(playerId, boatId, action);
    });

    newSocket.on('MaxCount',(MaxCount) => {
      alertService.error('Max hits reached', `You have already used allowed max 1000 hits`);
    });

    newSocket.on('updateUserPoints',(userPoints) => {
      const userId = currentUser?.SID; // Replace with your actual user ID or identification logic

      // Find the current user's entry in the received data
      const userData = userPoints.find(([id]) => id === userId);

      if (userData) {
        const { count, rank: userRank } = userData[1]; // Destructure user data
        setHits(count); // Update hits with the count value
        setRank(userRank); // Update rank with the rank value
       //console.log(userData);
      }
    
    });

    fetchData();
    return () => {
      newSocket.off('MaxCount');
      newSocket.off('updateUserPoints');
      newSocket.off('updateBoats');
      newSocket.off('animateAvatar'); // Cleanup this listener too
      newSocket.disconnect();
    };
    
  }, [currentUser]);

  return (
    <div className="main-content right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left pe-0">
          <div className="main-content-boat">
          <div className={`combined-header ${isOverlayVisible ? "hide-timer" : ""}`}>
            <div className="header-rows">
              <div className="team-info-container">
                {boats.map((boat) => (
                  <div key={boat.team} className="team-info">
                    <img src={cdn + boat?.pic} alt={boat.team} />
                    <div className="team-score">{boat.score}</div>
                  </div>
                ))}
              </div>

              {!isOverlayVisible && (
                <div className="race-timer">
                  Race ends in: {raceTimeLeft}
                </div>
              )}
            </div>

            {/* Avatar animation will be in a row below */}
            <div className="avatar-animation-container">
              {/* Avatar animation will be injected dynamically here */}
            </div>
          </div>

            <div className="game">
              <div className="background">             
                {boats.length > 0 && boats.map((boat) => (
                   <React.Fragment key={boat.team}>
                  <div onClick={() => handleTap(boat?.team,boat?.level)}
                    key={boat.team}
                    id={`boat-${boat.team}`}
                    className="boat-container pointer"
                  >
                    <div className="boat-info strong">                    
                    
                      <center>
                      
                        <Avatar sx={{ width: 75, height: 75 }} src={cdn + boat?.pic}>
                        </Avatar>  
                      </center>
                    </div >
                    <div  className='bg-white text-black p-1 font-xssss strong '>Lvl {boat?.level}</div> 
              
                    <img
                      src="https://files.geetsuhane.com/boatrace/boat88.png"
                      alt={`Boat ${boat.team}`}
                      className="boat-image"
                    />
                  </div>

                   
                 </React.Fragment>
                ))}
              </div>
            </div>
            {!isOverlayVisible  && 
            <>
                 {/* Dynamically Positioned Images */}
                 <div className={`position-left-center ${visiblePositions.leftCenter ? "visible" : ""}`}>
                  <img
                    src={shuffledImages[0]?.src}
                    alt="Image 1"
                    onClick={() => handleImageClick(0)}
                    className={spinningImage === 0 ? "spinning" : ""}
                  />
                </div>
                <div className={`position-middle-center ${visiblePositions.middleCenter ? "visible" : ""}`}>
                  <img
                    src={shuffledImages[1]?.src}
                    alt="Image 2"
                    onClick={() => handleImageClick(1)}
                    className={spinningImage === 1 ? "spinning" : ""}
                  />
                </div>
                <div className={`position-right-center ${visiblePositions.rightCenter ? "visible" : ""}`}>
                  <img
                    src={shuffledImages[2]?.src}
                    alt="Image 3"
                    onClick={() => handleImageClick(2)}
                    className={spinningImage === 2 ? "spinning" : ""}
                  />
                </div>
                <div className={`position-right-bottom ${visiblePositions.rightBottom ? "visible" : ""}`}>
                  <img
                    src={shuffledImages[3]?.src}
                    alt="Image 4"
                    onClick={() => handleImageClick(3)}
                    className={spinningImage === 3 ? "spinning" : ""}
                  />
                </div>

                {/* Animated Value */}
                {animateValue && (
                  <div className="animate-value">
                    {animateValue}
                  </div>
                )}
              </>
          }
             {/* Render the rank and hits only once, after the first boat */}
             {!isOverlayVisible  && (
                      <div className="user-rank-hits-container">
                                            <span className='font-xssss text-white text-center counter btn-round-xs bg-secondary  me-3'> {tool} </span>
                        <span className="rank-text"> Rank: {rank}</span>
                        <span className="hits-text pl-10"> Hits: {hits}/500</span>
                      </div>
                    )}
 

           
            {isOverlayVisible && (
              <div className="overlay">
                <TeamSelection />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoatRace;