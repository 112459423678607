import Swal from 'sweetalert2';

const alertService = {
  confirm: async (title, text, confirmButtonText = 'Yes', cancelButtonText = 'Cancel') => {
    const result = await Swal.fire({
      title,
      text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText,
      cancelButtonText,
    });

    return result.isConfirmed;
  },

  alertInput: async(title) => {
    const { value: text } = await Swal.fire({
      input: 'textarea',
      inputLabel: title,
      inputPlaceholder: 'Type your message here...',
      inputAttributes: {
        'aria-label': 'Type your message here'
      },
      showCancelButton: true
    })
    
    if (text) {
      return text;
    }
},

radioInput: async(title) => {
  /* inputOptions can be an object or Promise */
const inputOptions = new Promise((resolve) => {
  setTimeout(() => {
    resolve({
      'Coins': 'Coins',
      'Points': 'Points',
    })
  }, 1000)
})

const { value: offer } = await Swal.fire({
  title: 'Select offer',
  input: 'radio',
  inputOptions: inputOptions,
  showCancelButton: true,
  
})

if (offer) {
  return offer;
}
},

  exchange: async (title, text, confirmButtonText = 'Exchange Coins', cancelButtonText = 'Cancel') => {
    const result = await Swal.fire({
      title,
      text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText,
      cancelButtonText,
    });

    return result.isConfirmed;
  },

  vote: async(title,text,confirmButtonText = 'Submit', cancelButtonText = 'Cancel') => {
    const result =await Swal.fire({
      title,
      icon: 'question',
      input: 'range',
      text,
      inputAttributes: {
        min: 1,
        max: 100,
        step: 1
      },
      inputValue: 50,
      showCancelButton: true,
      confirmButtonText,
      cancelButtonText,
    })
    return result.value;
  },

  auction: async(title,text,min,max,confirmButtonText = 'Submit', cancelButtonText = 'Cancel') => {
    const result =await Swal.fire({
      title,
      icon: 'question',
      input: 'range',
      text,
      inputAttributes: {
        min: min,
        max: max,
        step: 1
      },
      inputValue: min,
      showCancelButton: true,
      confirmButtonText,
      cancelButtonText,
    })
    return result.value;
  },



  success: (title, text) => {
    Swal.fire({
      title,
      text,
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
    });
  },

  wait: (title, text) => {
    Swal.fire({
      title,
      text,
      icon: 'success',
      timer: 15000,
      timerProgressBar: true,
    });
  },

  error: (title, text) => {
    Swal.fire({
      title,
      text,
      icon: 'error',
    });
  },

  warning: (title, text) => {
    Swal.fire({
      title,
      text,
      timer: 2000,
      icon: 'warning',
    });
  },

  info: (title, text) => {
    Swal.fire({
      title,
      text,
      timer: 2000,
      icon: 'info',
    });
  },

  imgAlert: (title, text,imgurl,imgalt) => {
    Swal.fire({
      title,
      text,
      timer: 3000,
      imageUrl:imgurl,
      imageWidth: 300,
      imageHeight: 300,
      imageAlt:imgalt
    });
  },


  claimAlert: async (title, text,imgurl, confirmButtonText = 'Claim points', cancelButtonText = 'Cancel') => {
    const result = await Swal.fire({
      title,
      text,     
      showCancelButton:false,
      imageUrl:imgurl,
      confirmButtonText,
      cancelButtonText,
    });

    return result.isConfirmed;
  },

};

export default alertService;
