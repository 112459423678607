import ApiService from "./api.service";
import axios from "axios";

class ProfileService {
  constructor(apiService) {
    this.apiService = apiService;
  }


  async saveProfile({ uid,dp,pname, location, bio,dobmon,dobday }) {
    
    try {
      const response = await this.apiService.post("/profile/updateProfile", {
        uid,
        pname,
        dp,
        location,
        bio,
        dobmon,
        dobday
      });
      return response.message;
    } catch (error) {
      throw new Error('Error: Could not save profile!');
    }
  }
  async getProfile({ uid }) {
    try {
      //console.log(uid);
      const response = await this.apiService.post("/profile/getProfile", {
        uid
      });
     return response.profile;
    } catch (error) {
      throw new Error('Error: Could not fetch profile!');
    }
  }
  async deleteAlbum({ uid,filePath }) {
    try {
      console.log(uid);
      const response = await this.apiService.post("/profile/deleteAlbum", {
        uid,
        filePath
      });
     //return response.profile;
    } catch (error) {
      throw new Error('Error: Could not delete group!');
    }
  }

  async uploadFile({ file,filePath,path }) {
    try {
      console.log('filepath'+file.path);
      const response = await this.apiService.post("/profile/uploadFile", {
        file,
        filePath,
        path
      }, { headers: {'Content-Type': 'multipart/form-data'}});
     //return response.profile;
    } catch (error) {
      throw new Error('Error: Could not upload file!');
    }
  }

  async getResult({ qry }) {
    try {
     
      const response = await this.apiService.post("/profile/getResult", {
        qry
      });
      return response;
      //console.log(response);
    } catch (error) {
      throw new Error('Error: Could not fecth result!');
    }
  }

  async auditData({ userid,activity }) {
    try {
     
      const res = await axios.get("https://geolocation-db.com/json/f2e84010-e1e9-11ed-b2f8-6b70106be3c8");
      //console.log(res.data);
      const qry =`insert into gsglobal.activity(userid, ip,longitude, latitude,activity) values('${userid}','${res.data.IPv4}','${res.data.longitude}', '${res.data.latitude}', '${activity}' )`;
      //console.log(qry);
      const response = await this.apiService.post("/profile/getResult", {
        qry
      });
      //console.log(response);
    } catch (error) {
      throw new Error(error+'Error: Could not insert audit data!');
    }
  }
  async checkFraud({userid,ip }) {
    try {
     console.log(userid)
      const res = await axios.get("https://geolocation-db.com/json/f2e84010-e1e9-11ed-b2f8-6b70106be3c8");
      if(res?.data?.IPv4===ip) {
         return;
      } else if(ip=="" || ip=="127.0.0.1") {
        const qry =`update users set ipAddress ='${res.data.IPv4}' where id='${userid}';`;
        const response = await this.apiService.post("/profile/getResult", {
          qry
        });
      } else {
        const qry =`update users set ipAddress ='${res.data.IPv4}' where id='${userid}';`;
        const response = await this.apiService.post("/profile/getResult", {
          qry
        });
        return "fraud";
      }
    
     
    } catch (error) {
      
    }
  }

  async checkIP({ userid,activity }) {
    try {
     
      const res = await axios.get("https://geolocation-db.com/json/f2e84010-e1e9-11ed-b2f8-6b70106be3c8");
      //console.log(res.data);
      const qry =`select 1 from activity r where DAY(r.updatedAt)=DAY(CURRENT_DATE) AND MONTH(r.updatedAt)=MONTH(CURRENT_DATE) AND YEAR(r.updatedAt)=YEAR(CURRENT_DATE)  and ip='${res.data.IPv4}' and activity='${activity}' and longitude='${res.data.longitude}';`
      // const qry =`insert into gsglobal.activity(userid, ip,longitude, latitude,activity) values('${userid}','${res.data.IPv4}','${res.data.longitude}', '${res.data.latitude}', '${activity}' )`;
      //console.log(qry);
      const response = await this.apiService.post("/profile/getResult", {
        qry
      });
      return response;
     // console.log(response[0]);
    } catch (error) {
      throw new Error(error+'Error: Could not insert audit data!');
    }
  }
  async checkBanned({ userid }) {
    try {
      const qry= `CALL sp_checkBanned('${userid}');`
      const response = await this.apiService.post("/profile/getResult", {
        qry
      });
      return response;
      console.log(response[0]);
    } catch (error) {
      throw new Error(error+'Error: Could not insert audit data!');
    }
  }

  async mixdata({ audio, music}) {
    try {
      //const qry= `CALL sp_checkBanned('${userid}');`
      const response = await this.apiService.post("/profile/mixit", {
        audio, music
      });
      return response;
      console.log(response[0]);
    } catch (error) {
      throw new Error(error+'Error: Could not insert audit data!');
    }
  }

}

// Example usage:
const apiService = new ApiService();
const profileService = new ProfileService(apiService);

export default profileService;
