import React, {Fragment,useContext,useEffect,useState,useRef } from "react";
import profileService from "../services/profile.service";
import Marquee from "react-fast-marquee";
import alertService from '../services/sweetAlert';
import { AuthContext } from "../context/authContext";
import { Link, useNavigate } from 'react-router-dom';
import dayjs from "dayjs";
//import utc from "dayjs/plugin/utc";
//import relativeTime from "dayjs/plugin/relativeTime";
import {  animated,useSpring } from 'react-spring';
import useSound from 'use-sound';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Hidden, ListItemText } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import Ratings from "./Ratings";
import { NotificationContext } from "../context/notificationsContext";


function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}


export default function  Auction() {
    const radius = 150; // Example orbit radius

    const { currentUser,getUserDetails,userData} = useContext(AuthContext);
    const {sendMessageToGroup,activity} = useContext(NotificationContext);
    const navigate = useNavigate()
    const cdn = 'https://files.geetsuhane.com/';
    const [loading, setLoading] = useState(false);
    const [inactiveMembers, setInactivemembers] = useState(null); 
    const [planets, setPlanets] = useState(null);
    const [atmPlayers, setAtmPlayers] = useState(null);
    const [gspWinners, setGSPWinners] = useState(null);    
    const [planetWinners, setPlanetWinners] = useState(null);
    const [gsptopwinners, setGSPTopWinners] = useState(null);
    const [yourBet, setYourBet] = useState(0);
    const [ticker, setTicker] = useState(false);
    const [animate, setAnimate] = useState(false);
    const [open, setOpen] = useState(false);
    const [coins, setCoins] = useState(0);
    const [planet, setPlanet] = useState(10);
    const [planetWorth, setPlanetWorth] = useState(10);
    const [planetPic, setPlanetPic] = useState(null);
    const [planetName, setPlanetName] = useState('');
    const [round, setRound] = useState(null);
    const [rounds, setRounds] = useState(null);
    const [roundData, setRoundData] = useState(null);
    const [roundResultOpen, setRoundresultOpen]=useState(false);
    const [result, setResult]=useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [adminBet,setAdminBet] = useState(false);
    const [roundAdminOpen,setRoundAdminOpen] = useState(false);
    const [gold,setGold] = useState(0);

    const [playCoins] = useSound(cdn+'sound/coins.mp3');
    const [playLose] = useSound(cdn+'sound/lose.mp3');

    
    const sendMessage = async (msg) => {
      sendMessageToGroup ({
        message: msg,
        senderId: currentUser.id,
        name: currentUser.name,
        picture: currentUser.picture,
        receiverId: 2,
        groupName : 'Activity',
        time: new Date(),
        msgType: 'text',
        sender: currentUser
      })

    };

    var getGold = async () => {
      var goldStatus =await  profileService.getResult({
          qry: `CALL sp_getGoldBalance('${currentUser?.SID}');`
      });
     setGold(goldStatus?.[0][0]?.gold);
     //console.log(goldStatus)
   //localStorage.setItem("gold",goldStatus?.[0][0]?.gold)
       
   }
 
      const handleClickOpen = () => {
        setOpen(true);
      };
      const handleRoundResultClose = () => {       
        setRoundresultOpen(false);
        setRoundAdminOpen(false);

      };

      const handleClose = () => {
        setOpen(false);
        setRoundresultOpen(false);
        setResult(false);
      };
      const handleRoundResult = (round) => {
        getRound(round);
        setRoundresultOpen(true);
      };

      const handleResult =  () => {
        getRounds()
        setResult(true);
      };
      
  
    // const props = useSpring({
    //   from: { transform: "translate(0px,0px)" },
    //   to: [{ transform: animate  ? "translate(0px,200px)"  : "translate(0px,0px)"  }],           
    //   config: { duration: "500" },
    //   loop: true,
    //   reset: true
    // });

    const props = useSpring({
      from: { transform: "rotate(0deg)" },
      to: [{ transform: animate ? "rotate(360deg)" : "rotate(0deg)" }],
      config: { duration: 500 }, // Set duration for fast rotation
      loop: true,  // Loop the rotation continuously
      reset: true
    });


    const propsuser = useSpring({
      from: { transform: "translate(0px,0px)" },
      to: [{ transform: animate  ? "translate(0px,-200px)" : "translate(0px,0px)" }],          
      config: { duration: "500" },
      loop: true,
      reset: true
    });

    const getPlanets = async () => {
      var planetData = await profileService.getResult({
                  qry: `select id,name,pic,worth from grps where id>1 and worth>0;`
              });
        setPlanets(planetData?planetData:null);
    }

    const gstBet = async (pid,pname,worth) => {
    
      // if(userData?.gold>1000000000) {
      //   alertService.error('Not Allowed','You have more than 1000000000 coins, please spend them first!');
      //   return;
      // }

     if( parseInt(coins)> 9999999999) {
        alertService.info('Error!', 'Too big number');
        return;
      }
 
      if( parseInt(ticker)===59 || parseInt(ticker)<=5) {
        alertService.info('Error!', 'Bet will be allowed in next round');
        return;
      }
     
      var offer =coins;
      if(offer) {                  
              var submitOffer = await profileService.getResult({
                  qry: `CALL sp_insertGSPBet('${currentUser.id}',${offer},${pid},${worth});`
              }).then (res => {
                  //console.log(res?.[0][0]?.resp)
                  if(res?.[0][0].resp==='success') {
                    setYourBet(parseInt(yourBet)+parseInt(offer));
                  
                      //alertService.info('Your bet has been successfully submitted, result will be displayed within 1 min');
                  } else if(res?.[0][0]?.resp==='not available') {
                      alertService.error('Error!', 'You do not have enough GS gold coins');
                  } else if(res?.[0][0]?.resp==='not allowed') {
                      alertService.error('Not Allowed', 'You have more than 10000000 coins, please spend them first!');
                  } else  {
                      alertService.error('Error!', 'Oops, please try later');
                  } 
                  getGSPData();
              })          
      }
    }

    const getGSPData = async () => {

      var planets = await profileService.getResult({
        qry: `select w.id as wid ,g.id,g.name,g.pic,g.worth from winners w join grps g on w.uid=g.id  where w.title='gsp' order by w.id desc limit 6;`
        
    });
  
    
      setPlanetWinners(planets?planets:null);
      getUserDetails(currentUser?.SID);

 
          var winners = await profileService.getResult({
            qry: `         select distinct u.picture,p.round, sum(p.val) as worth,u.SID from users u join
            (select d.uid,d.round, sum(d.worth*d.gold) val from gsp d 
            where d.status=3 and d.gold>0 and round>0 group by d.uid,d.round
                     order by d.round desc limit 50) p on u.id = p.uid
                     group by u.id,u.name,u.picture,p.round 
                     order by p.round desc,sum(p.val) desc limit 3
            ;`
      });
      //console.log(winners)
        setGSPWinners(winners?winners:null);

        var topwinners = await profileService.getResult({
          qry: `select left(u.name,12) as name,u.picture,us.gname,sum(gold*worth) as worth,u.SID
          from gsp g join users u on g.uid=u.id
                join usersum us on us.uid=u.id
          where g.status=3 group by u.id,u.name,u.picture 
          order by sum(gold*worth) desc limit 3;`

      });
      //console.log(winners)
      setGSPTopWinners(topwinners?topwinners:null);
      
    }

    const setBetRound = () => {
   
      const h =parseInt(new Date().getUTCHours());
      const m =parseInt(new Date().getUTCMinutes());
      
  
      setRound(h*100+m);
      //getRound(h*100+m-1);
    }

    const resetBet = async () => {
      const t = new Date().getSeconds();
     
      setTicker(t);
         if(t===59  ) {         
          setAnimate(true);           
         } 
         if(t===4)  getGSPData();
         if(t===5) {              
          setAnimate(false);
          if(currentUser) {
            await checkWinner();
            getUserDetails(currentUser?.SID);
          }         
           setBetRound();
           setYourBet(0);          
          }
    }

    const checkWinner = async () => {
      const h =parseInt(new Date().getUTCHours());
      const m =parseInt(new Date().getUTCMinutes());
      const roundNb = h*100+m-1;
      var planet =await  profileService.getResult({
        qry: `select g.name,g.pic,g.worth, if(w.status=3 and p.uid='${currentUser?.id}' , p.gold*g.worth,0) as winning 
        from grps g join gsp p on g.id=p.planet and p.round=${roundNb} and p.status=3
        left join gsp w on p.round=w.round 
        where  w.uid='${currentUser?.id}' order by winning desc limit 1;`
        
    });  
    //console.log(planet)
       if(planet?.length>0)       
          if(planet?.[0]?.winning>0) {
            playCoins();
            alertService.imgAlert(planet?.[0]?.winning,planet?.[0]?.winning>0?'Congratulations You Won!':'',cdn+planet?.[0]?.pic,'');
          } else {
            alertService.imgAlert('','Oops, try again please!',cdn+planet?.[0]?.pic,'');
          }

     // alertService.imgAlert(planet?.[0]?.winning?planet?.[0].winning:'Oops, try again please!',planet?.[0]?.winning>0?'Congratulations You Won!':'',cdn+planet?.[0]?.pic,'');
    }

    const getRounds = async () => {
      var roundsData = await profileService.getResult({
                  qry: `
                  select distinct  p.round, g.pic,g.name,g.worth from  gsp p join grps g on p.planet=g.id  where p.status=3 order by round desc;`
              });
          //console.log(roundDetails)
        setRounds(roundsData);
    }

    const getRound = async (round) => {
      var roundDetails = await profileService.getResult({
                  qry: `
                  select distinct r.pic,r.worth,r.winner,w.gold from
                  (select distinct grp.id, grp.pic, grp.worth,if(p.status=3,'*','') as winner,p.round from
                  (select distinct g.id as id,g.pic,g.worth
                  from grps g where g.worth>0) grp  left join  gsp p  on grp.id=p.planet and p.round=${round}) r 
                  left join gsp w on r.round=w.round  and r.id=w.planet and w.uid='${currentUser?.id}';`
              });
          //console.log(roundDetails)
        setRoundData(roundDetails);
    }

    const getAdminBet =async () => {
      const bet = await profileService.getResult({
                qry:`select distinct u.name, u.picture, p.round,p.planet,g.pic as planetpic, p.worth, p.gold, p.worth*p.gold as total from gsp p join grps g on p.planet=g.id join users u on p.uid=u.id where p.status=1 order by  p.worth*p.gold, u.name`
      });

      setAdminBet(bet);
      setRoundAdminOpen(true);
      //console.log(bet)
    }

    const setGSP =async (p,w) => {
      const planet = await profileService.getResult({
                qry:`update config c set c.key=${p}, c.value=${w} where c.section='gsp'  and c.status=1`
      });
     
      //console.log(planet)
    }

    useEffect(() => {
        async function fetchList() {
         await getGSPData();
          await setBetRound();
         await getPlanets();
          await getGold();
          if(currentUser)
          getUserDetails(currentUser?.SID);
          // const gsp = setInterval(() => {               
          //   getGSPData()
          // }, 3000);
          const reset = setInterval(() => {               
            resetBet()
          }, 1000);
              
       
        }
         fetchList();           
         
      }, [currentUser]);


        return (
            <Fragment>
            
        <div >
       
          <center>
            
            <div id='gst' className="m-0 p-0"> {currentUser?.SID==='008' &&
                  <span onClick={getAdminBet} className="text-red font-xssss">GSP Control Panel </span>
            }
           
              {/* admin data */}
              <div>
            
            <Dialog
           
              open={roundAdminOpen}
              onClose={handleRoundResultClose}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle className="bg-primary-gradiant" id="responsive-dialog-title">
              <span onClick={getAdminBet}  className="text-white font-xssss"> <i className="feather-refresh--ccw btn-round-xssss bg-primary-gradiant me-1"></i>{"GSP Control Panel"} - {ticker}</span>
              </DialogTitle>
              <DialogContent>
              <div className="p-0 m-0 d-flex position-relative ">  
              <List sx={{ pt: 0 }}>
                { adminBet ? adminBet?.map((value, index) => (
        <ListItem alignItems="flex-start" disableGutters key={index}>
          
          <ListItemText>
        <img  className="rounded-img-sm mx-2 pointer"
                      src={cdn+'users/'+value.picture }
                    /> 
            </ListItemText>
            <ListItemText>
              <span className="font-xssss">{value?'X'+value?.worth:''} {value?.name} </span> 
                
            </ListItemText>
            <ListItemText>
        <img onClick={()=>setGSP(value?.planet,value?.worth)} className="rounded-img-sm mx-2 pointer"
                      src={cdn+value.planetpic }
                    /> 
            </ListItemText>
            <ListItemText> <span className="font-xsssss">
            X{value?.worth} - {value?.total}</span>
            </ListItemText>        
         
        </ListItem>

                )):''}
       </List>
  </div>
              </DialogContent>
              <DialogActions>
               
                <Button onClick={handleRoundResultClose} autoFocus>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>
              {/* admin data -end */}
      
              <div>
                    
                      <div>
                {/* <Button variant="outlined" >
                  Open draggable dialog
                </Button> */}
                <Dialog
                  open={open}
                  onClose={handleClose}
                  PaperComponent={PaperComponent}
                  aria-labelledby="draggable-dialog-title"
                >
                  <DialogTitle className="font-xss" style={{ cursor: 'move' }} id="draggable-dialog-title">
                  How many coins you want to bet on {planetName}?
                  <img className="rounded-img-sm mx-1 pointer"
                  src={cdn+planetPic }
                
                />{planetWorth?'X'+planetWorth:''}
              
                  </DialogTitle>
                  <DialogContent>
                  <TextField
                  // autoFocus
                  margin="dense"
                  id="txtCoins"
                  type="number"
                  fullWidth
                  variant="standard"
                  value={coins}
                  onChange={e => {
                  setCoins(e.target.value)                
                  }}
                />
                <Button onClick={e=>setCoins(10)} size="small" color="success" variant="outlined">10</Button>
                <Button onClick={e=>setCoins(100)} size="small" color="success" variant="outlined">100</Button>
                <Button onClick={e=>setCoins(500)} size="small"  color="success" variant="outlined">500</Button>
                <Button onClick={e=>setCoins(1000)} size="small" color="success" variant="outlined">1000</Button>
                <Button onClick={e=>setCoins(10000)} size="small" color="success" variant="outlined">10000</Button>
                <Button onClick={e=>setCoins(25000)} size="small" color="success" variant="outlined">25000</Button>
                <Button onClick={e=>setCoins(50000)} size="small" color="success" variant="outlined">50000</Button>
                <Button onClick={e=>setCoins(100000)} size="small" color="success" variant="outlined">100000</Button>
                  </DialogContent>
                  <DialogActions>
                    
                    <Button size="large" onClick={()=>{gstBet(planet,'',planetWorth);handleClose()}}  variant="contained" color="error">BET</Button>
                  </DialogActions>
                </Dialog>
              </div>

              <div>
              <Dialog
              fullScreen={fullScreen}
              open={result}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle  className="font-xss" id="responsive-dialog-title">
                {"Result"}
              </DialogTitle>
              <DialogContent  >
               
                <List sx={{ pt: 0 }}>
                  { rounds ? rounds?.map((value, index) => (
          <ListItem alignItems="flex-start" disableGutters key={index}  onClick={()=>handleRoundResult(value?.round)}>
            <ListItemText>
             <span className="font-xssss text-blue" >{value?.round}</span>   
              </ListItemText>
            <ListItemText>
          <img  className="rounded-img-sm mx-2 pointer"
                        src={cdn+value.pic }
                      /> 
              <span className="font-xsssss">{value?'X'+value?.worth:''}</span> <span className="font-md strong blink-n text-red">
                   {value?.winner} </span>
              </ListItemText>
              <ListItemText>
                
              </ListItemText>
              <ListItemText>
                <span className="text-right font-md pl-10">...</span> 
                </ListItemText>
          </ListItem>

                  )):''}
         </List>
                
              </DialogContent>
              <DialogActions>
             
                <Button onClick={handleClose} autoFocus>
                <span className="font-xsss">Close</span>   
                </Button>
              </DialogActions>
            </Dialog>
              </div>
           
           <div>
            
              <Dialog
             
                open={roundResultOpen}
                onClose={handleRoundResultClose}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle className="bg-primary-gradiant" id="responsive-dialog-title">
                <span className="text-white font-xssss">{"Round Result"}</span>
                </DialogTitle>
                <DialogContent>
                <div className="p-0 m-0 d-flex position-relative ">  
                <List sx={{ pt: 0 }}>
                  { roundData ? roundData?.map((value, index) => (
          <ListItem alignItems="flex-start" disableGutters key={index}>
            <ListItemText>
          <img  className="rounded-img-sm mx-2 pointer"
                        src={cdn+value.pic }
                      /> 
              </ListItemText>
              <ListItemText>
                <span className="font-xssss">{value?'X'+value?.worth:''}</span> <span className="font-md strong blink-n text-red">
                   {value?.winner} </span>
              </ListItemText>
              <ListItemText>
                <span className="text-right font-xsssss pl-10">{value?.gold>0?value.gold:'No Bet'}</span> 
                </ListItemText>
          </ListItem>

                  )):''}
         </List>
    </div>
                </DialogContent>
                <DialogActions>
                 
                  <Button onClick={handleRoundResultClose} autoFocus>
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
              </div>
           
            <div className="pt-2 pb-2">
        <div  className="bg-mini-gradiant text-white p-1 "><span  className="font-xssss  p-2 strong">GSP : {ticker}</span>
      
         {round>0 && (
            <span className=" font-primary-gradiant p-1 font-xssss"> Round: {round} </span>
         )

         }
         <span onClick={handleResult} className="font-xsssss bg-primary-gradiant rounded-xl  pt-2 pb-2 ps-2 pe-2 ms-1 ls-2 "> Result </span>
         <Ratings uid="gsp" type="game" size="small"></Ratings>
       </div>
      <div style={{backgroundImage: `url("https://files.geetsuhane.com/home/gsp-s.gif")`,width:'100%', height:'350px'}}><div>
      <span  className="card-body p-1 m-0 d-flex position-relative font-xsssss text-white text-center">
      
            {planetWinners ? planetWinners?.map((value, index) => (
              <animated.div key={index} style={props}>
            {/* <a onClick={()=> gstBet(value?.id,value?.name,value?.worth)}  > */}
            <a className="pointer" onClick={()=>{setPlanet(value?.id);setPlanetWorth(value?.worth);setPlanetPic(value?.pic);setPlanetName(value?.name);handleClickOpen()}}  >
            <Avatar alt="Planets"  sx={{ width: 56, height: 56 }}  src={cdn+value?.pic } />
            {/* <img className="rounded-img-sm mx-1 pointer"
                  src={cdn+'/'+value?.pic }
                
                /> */}
                 <h4  className="fw-700 text-white font-xssss mt-1 "> {value?'X'+value?.worth:''}</h4>
            </a> 
          </animated.div>
            )):''}
                
            </span>
            </div>
            <Marquee speed="15" pauseOnHover="pause" direction="right" className="font-xl text-white pt-1" >
    
    <span className="card-body p-1 mt-10 d-flex position-relative ">  
    {planets ? planets?.map((value, index) => (
      <animated.div key={index} style={props}>
    <a className="pointer" onClick={()=>{setPlanet(value?.id);setPlanetWorth(value?.worth);setPlanetPic(value?.pic);setPlanetName(value?.name);handleClickOpen()}}  >
    <Avatar alt="Planets"  sx={{ width: 56, height: 56 }}  src={cdn+value?.pic } />


        <h4  className="fw-700 text-white font-xssss mt-1 "> {value?'X'+value.worth:''}</h4>
    </a> 
    </animated.div>
    )):''}
        
    </span>
   
</Marquee>
      {/* <div>
           
           {gspPlayers?.length &&
                 <span className="p-1 font-xssss text-white"> Players: 

           {gspPlayers ? gspPlayers?.map((value, index) => (

               <a  key={index} href={`/userpage?uid=${value?.id}`}>
               <img
                 className="rounded-img-sm mx-1 pointer"
                 src={
                   value?.picture.includes('googleusercontent')?value.picture:cdn+'users/'+value.picture
                  
                 }
                 alt={value?.name}
               />
               </a> 
               )):''}
                   
                 </span>
               }
           </div> */}

<div  >
            <center>
        { gspWinners &&
   
 
            <div  className="card-body p-1 m-50 d-flex position-relative ">
            {gspWinners?.map((value , index) => (
                
                                        <div  style={{maxWidth:'170px'}}  key={index} className="col-sm-5 pe-2 ps-1 ">
 <animated.div style={propsuser}>
            <a  href={`/userpage?uid=${value?value.SID:''}`}>
            <figure className="avatar me-1">
            {value?.picture ? (
                <Avatar alt="Planets"  sx={{ width: 56, height: 56 }}   src={
                  value?.picture.includes('googleusercontent')?value.picture:value.picture.includes('groups/')?cdn+value.picture:
                  cdn + "users/"+
                  value.picture
              } />
                // <img
                // className='rounded-img-sm mx-1'
                // src={
                //     value?.picture.includes('googleusercontent')?value.picture:value.picture.includes('groups/')?cdn+value.picture:
                //     cdn + "users/"+
                //     value.picture
                // }
                // alt=''
                
                // />
            ) : (
                <div className='align-items-center bg-grey d-flex justify-content-center rounded-img-sm mx-1'>
                <span className='ti-user font-sm'></span>
                </div>
            )}
                          <h4  className="fw-700 text-grey-900 font-xsssss mt-1 "> 

            <div  className='message-wrap justify-content-center text-white align-items-center mt-0 pt-0 font-xssssss'>
            <img src="https://files.geetsuhane.com/gifts/gold.png" alt="GS Gold" className="w10 rounded-3 " />{value?.worth}                
            </div>
            </h4>

            </figure>
            
            </a> 
            </animated.div> 
          
            </div>
           ))} 
          
            </div>
 
        }
          
       
        </center>
        </div>

      </div>
   
      <div className="bg-primary-gradiant text-white font-xsssss text-right p-2">
        {yourBet>0 && (
           <span className="bg-red-gradiant p-1"> Bet: 
          <img src="https://files.geetsuhane.com/gifts/gold.png" alt="GS Gold" className="w15 rounded-3 " /> {yourBet}
          </span>
        )}
     
       <span className="bg-primary-gradiant p-1">Account Balance:
       <img src="https://files.geetsuhane.com/gifts/gold.png" alt="GS Gold" className="w15 rounded-3 " /> {gold}</span> 
      
     
      </div>
    </div>


    <div  >
            <center>
        { gsptopwinners &&
   
 
            <div  className="card-body p-1 m-0 d-flex position-relative ">
            {gsptopwinners?.map((value , index) => (
                
                                        <div  style={{maxWidth:'170px'}}  key={index} className="col-sm-5 pe-2 ps-1 ">

            <a  href={`/userpage?uid=${value?value.SID:''}`}>
            <figure className="avatar me-1">
            {value?.picture ? (
                <img
                className='rounded-img-sm mx-1'
                src={
                    value?.picture.includes('googleusercontent')?value.picture:value.picture.includes('groups/')?cdn+value.picture:
                    cdn + "users/"+
                    value.picture
                }
                alt=''
                
                />
            ) : (
                <div className='align-items-center bg-grey d-flex justify-content-center rounded-img-sm mx-1'>
                <span className='ti-user font-sm'></span>
                </div>
            )}
            
            </figure></a> 
            
            <h4  style={{fontSize:"8px"}}><span className="strong ">{value?.name}</span> 
            <div> <Ratings uid={value?.SID} size="small"></Ratings></div>   
            {/* <div> <img  src="https://files.geetsuhane.com/home/clap-s.gif"></img></div> */}

            <div  className='message-wrap justify-content-center text-red align-items-center mt-0 pt-0 '>
           
            <img src="https://files.geetsuhane.com/gifts/gold.png" alt="GS Gold" className="w10 rounded-3 " />    {value?.worth}
                
            </div>
            
            <div className='message-wrap justify-content-center text-yellow align-items-center mt-0 pt-0 font-xssss'>
            
           GSP Star

        </div>
       
            <span  className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
            
                <Link to={`/search?groupDetail=${value?.gname}`} className="bg-secondary pointer p-1 font-xssss   text-white">   
                <i className="feather-globe btn-round-xssss bg-primary-gradiant me-1"></i>{value?value?.gname:''}  </Link>
            
            
            </span>
            </h4>
            
            </div>
           ))} 
          
            </div>
 
        }


            
        </center>
     
        </div>
        <div>
              <Link to="/cafe">
            <div className="font-xsssss strong text-red">GS Cafe found a new place, click on image below</div>
                    <div   style={{position:"relative", backgroundImage:`url(" https://files.geetsuhane.com/home/gscafe_v1.gif")`,width:"100%", backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundPosition:"center", height: "250px"}}  > </div>
              </Link>
          </div>
    
</div>
         

        </center>
        
        </div>
        <div className="text-center w-100 ">       
        <hr></hr>                                            
                            <Link  to="/gupshup" className={`bg-primary pointer mt-1 btn pt-2 pb-2 ps-3 pe-3 ms-1 ls-3 rounded-xl feather-message-square font-xsssss fw-700 ls-lg text-white`}>GupShup</Link>
                            <Link to="/gupshup?groupChat=group" className="bg-secondary pointer feather-globe mt-1 btn pt-2 pb-2 ps-3 pe-3 ms-1 ls-3 rounded-xl  font-xsssss fw-700 ls-lg text-white">Planet</Link>

  <Link to="/chatlist" className="feather-mail bg-red-gradiant pointer mt-1 btn pt-2 pb-2 ps-3 pe-3 ms-1 ls-3 rounded-xl  font-xsssss fw-700 ls-lg text-white ">Inbox</Link>
 
                              </div>
</Fragment>
        );
    }