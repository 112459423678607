import React, { createContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

import authService from "../services/auth.Service";
import toastr from "../services/toastr.service";
import "toastr/build/toastr.min.css";
import profileService from "../services/profile.service";
import alertService from "../services/sweetAlert";
import io from "socket.io-client";

// import { getUserDataByUID } from "../firebaseDB";

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userData, setuserData] = useState(null);

  const getUserDetails = async (uid) => {
    if(uid?.length>0) {
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/redis/rid`, {
            tmpidOrSID: uid
          });
          
          if (response.data) {
            setuserData(response.data);            
            //console.log(response.data);
          } else {
            console.log('No SID data found');
          }
        } catch (err) {
          console.log(err.response?.data?.message || 'Failed to fetch data');
        } 
      }
    }


  const login = async (user, method) => {
    try {
      if (method === "google") {
       
        const { name, email, picture, sub } = user;
        const userExists = await authService.userExists(sub);
        let u = null;
        if (!userExists.exists) {
          u = await authService.login({
            name,
            email,
            picture,
            uid: sub,
            authProvider: method,
          });
          localStorage.getItem("redirect")? (navigate(localStorage.getItem("redirect"))) :
          navigate(
            location.pathname.includes("login") ? "/" : location.pathname
          )
          
        }
        const r=userExists.exists ? userExists.user : u;
       // console.log(r)
        //  setCurrentUser(r);
        // localStorage.setItem('user', encryptData({ id: r?.id, name: r?.name,lead:r?.lead,
        //   picture:r?.picture,SID:r?.SID,status:r?.status,gname:r?.gname,gpic:r?.gpic,vip:r?.vip }));
      
        setIsLoggedIn(true);
       
      
        localStorage.setItem(
          "tmpgc",userExists.exists?userExists.user.tmpid:sub
        );
      }

      localStorage.getItem("redirect")? (navigate(localStorage.getItem("redirect"))) :
      navigate(
        location.pathname.includes("login") ? "/" : location.pathname
        )
    } catch (error) {
      console.log(error);
    }
  };

  const forgetpwd = async (email) => {
    console.log(email);
    const tempid = await profileService.getResult({
      qry: `select u.tmpid from users u  where u.email='${email}' ;`
  })
  //console.log(tempid)
    if(tempid?.[0]) {
      const sendemail = await authService.forgetpwd({mailaddress:email,tmpid:tempid?.[0]?.tmpid});
    } 
   
  }

  const resetpassword = async ({tempid,password}) => {

    const email = await profileService.getResult({
      qry: `select u.email from users u  where u.tmpid='${tempid}' ;`
  })
  //console.log(tempid)
    if(email?.[0]) {
      const sendemail = await authService.resetpassword({mailaddress:email?.[0]?.email,password:password});
    } 
   
  }

  const loginWithEmailPassword = async ({ email, password }) => {
    const { user, token } = await authService.loginWithEmailAndPassword({
      email,
      password,
    });
    if (token) {
      //localStorage.setItem("userAuth", JSON.stringify(user));
      localStorage.setItem(
        "tmpgc",user?.tmpid
      );
      
      console.log(user)
      setCurrentUser(user);
      // const r =user;
      // localStorage.setItem('user', encryptData({ id: r?.id, name: r?.name,lead:r?.lead,
      //   picture:r?.picture,SID:r?.SID,status:r?.status,gname:r?.gname,gpic:r?.gpic,vip:r?.vip }));

      //getUserDetails();
      setIsLoggedIn(true);
      
      localStorage.getItem("redirect")? (navigate(localStorage.getItem("redirect"))) :
      navigate(
        location.pathname.includes("login") ? "/" : location.pathname
        )
      toastr.success("you are loggedin successfully");

      return;
    }

    toastr.error("email or password is not correct");
    return;
  };

  const register = async ({ name, email, password }) => {
    try {
      const newUser = await authService.register({ name, email, password });
      console.log(newUser)
      if(newUser?.user==="exists") {
        toastr.error("This email id is alreay in use, please try some other email id");
        return;
      }
      if (newUser.token) {
        // console.log(newUser);
        // localStorage.setItem("userAuth", JSON.stringify(newUser));
        // setCurrentUser(newUser);
        // setIsLoggedIn(true);
        toastr.success("you are registered successfully, please login");
        navigate("/login");
      }
      return;
    } catch (error) {
      console.log(error);
    }
  };

  const logout = () => {
    localStorage.clear();
    setCurrentUser(null);
    setIsLoggedIn(false);
    navigate("/login");
  };
    
  function generateUUID() {
    let uuid = "";
    for (let i = 0; i < 32; i++) {
      if (i === 8 || i === 12 || i === 16 || i === 20) {
        uuid += "-";
      }
      uuid += Math.floor(Math.random() * 16).toString(16);
    }
    return uuid;
  };
  

  

  const autoLogin = async () => {
    setLoading(true); // Start loading state
  
    const tmpuser = localStorage.getItem("tmpgc");
    if (tmpuser && !currentUser) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_END_POINT}/redis/rid`,
          { tmpidOrSID: tmpuser }
        );

        if (response.data) {
          setCurrentUser(response.data);
          setIsLoggedIn(true);

          if (response.data.status === 2) {
            navigate("/denied");
          } else {
            // Preserve the query string by appending location.search
            navigate(location.pathname.includes("login") ? "/" : `${location.pathname}${location.search}`);
          }
        } else {
          console.log("No user data found");
          navigate("/login"); // No user data, navigate to login
        }
      } catch (err) {
        console.log(err.response?.data?.message || "Failed to fetch data");
        navigate("/login"); // Error fetching, navigate to login
      } finally {
        setLoading(false); // Stop loading regardless of success or failure
      }
    } else {
      // No session or already logged in
      setLoading(false);
      //if (!currentUser) {
        //navigate("/login"); // No user, navigate to login
      //}
    }
  };

  useEffect(() => {
    autoLogin();
  }, []); // Empty dependency array ensures it runs on mount


  const contextValues = {
    isLoggedIn,
    currentUser,
    login,
    logout,
    loading,
    loginWithEmailPassword,
    register,
    userData,
    getUserDetails,
    forgetpwd,
    resetpassword,
    //sendMessageToGroup,
    //activity
  };

  return (
    <AuthContext.Provider value={contextValues}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
