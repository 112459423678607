import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import relativeTime from 'dayjs/plugin/relativeTime'
import dayjs from 'dayjs'

import { PostsContext } from '../context/postsContext'
import { AuthContext } from '../context/authContext'
import Singleview from '../components/Singleview'
import SinglePostComment from './SinglePostComment'
import alertService from '../services/sweetAlert'
import profileService from "../services/profile.service";
import GiphyGif from '../components/GiphySearch'

// import GiphySearch from './giphySearch'
// import Tenor from 'react-tenor'
// import 'react-tenor/dist/styles.css'

dayjs.extend(relativeTime)
const cdn = 'https://files.geetsuhane.com/';
const ssd = process.env.REACT_APP_API_END_POINT+'/gsfiles/';
export default function PostComments () {
  const navigate = useNavigate()
 
  const { fetchSinglePost, singlePost, saveComment, organizeComments } =
    useContext(PostsContext)
  const { currentUser } = useContext(AuthContext)
  const [content, setContent] = useState('')
  const [parentId, setParentId] = useState(null)
  const [post, setPost] = useState(null)
  const [inputbox, setInputbox] = useState(false)
  const [taguser, setTaguser] = useState('')
  const [refresh, setRefresh] = useState(false)
  //console.log('hi')
  
  //const postId = localStorage.getItem('postid')
 
  //console.log(postId);
  const queryParams = new URLSearchParams(window.location.search)
  const postId = queryParams.get("postId");
  useEffect(() => {

    if (postId) {
      fetchSinglePost(postId)
       getPost(postId);
    }
    setRefresh(false);
  }, [postId,refresh])

  const SubmitHandler = async (categoty) => {
  if(categoty==='gif') {

  } else {

  }
    const success = await alertService.success(
      '',
      'Commented successfully'
    )
    //submitComment();
    insertComments('text',content);
    //setInputbox(false);
    setTaguser('');
    setContent('');
  }

  const submitComment = async () => {
    await saveComment({
      postId: singlePost.id,
      userId: currentUser.id,
      content: taguser?taguser+' '+content:content,
      parentId
    })
    
  }

  const getPost = async(id) => {
    //console.log(id);
    var postItem = await profileService.getResult({
      qry: `CALL sp_getSinglePost(${id});`
  }); 
    setPost(postItem?.[0][0]);
    // console.log(postItem[0][0])
  };
  const getComments = async(id) => {
    //console.log(id);
    var postItem = await profileService.getResult({
      qry: `CALL sp_getSinglePost(${id});`
  }); 
    setPost(postItem[0][0]);
    console.log(postItem[0][0])
  };

  const insertComments = async(ctype,msg) => {
    //console.log(id);
    if(currentUser?.SID==='Banned') return;
    var postItem = await profileService.getResult({
      qry: `CALL sp_insertComments(${singlePost.id},'${currentUser.id}', '${ctype==='gif'?msg:taguser?taguser+' '+content:content}','${ctype}', ${parentId});`
  }); 
  //getPost(postId);
  setRefresh(true);
}


  //console.log(id);
 
  const showInput = () => {
    setInputbox(true);
  }

  const deleteComments = async(id) => {
    const confirmed = await alertService.confirm(
      'Delete Message',
      'Are you sure you want to delete this message?'
    )

    if (confirmed) {
      // delete the item
      var deleteItem = await profileService.getResult({
        qry: `CALL sp_deleteComments('${currentUser.id}',${id});`
    });
      alertService.success(
        'Message Deleted',
        'The message has been successfully deleted.'
      )
      //getPost(postId);
      setRefresh(true);
    }
}

const sendGif = p => {
  setContent('');
  insertComments('gif',p);
}

  const organizedComments = organizeComments(singlePost?.comments)
  //console.log(organizedComments);
  return (
    <div className='main-content right-chat-active'>
      
      <div className='middle-sidebar-bottom'>
      <i
            onClick={() => {
              navigate(-1);
            }}
            className='feather-chevron-left cursor-pointer text-dark text-black-900 btn-round-sm font-lg'
          ></i>
        <div className='middle-sidebar-left'>
         
          <div className='card-body p-0 d-flex align-items-center position-relative'>
            
            <figure className='avatar me-3'>
            
              {/* {singlePost?.user ? (
                <img
                  src={
                    singlePost?.user?.picture
                      ? cdn + 'users/' + singlePost?.user?.picture
                      : ''
                  }
                  alt='avater'
                  className='shadow-sm rounded-circle w45'
                />
              ) : (
                <div className='align-items-center bg-grey d-flex justify-content-center rounded-img-sm'>
                  <span className='ti-user font-sm'></span>
                </div>
              )}
              {
                console.log(singlePost)
              } */}
            </figure>
          <div>
            
                      
                        <Singleview
                          id={post?.id}
                         
                          postimage={post?.mediaType== 'img' && post?.status!=99? cdn+'users/'+post.mediaUrl:(post?.mediaType== 'img' && post?.status==99?post.mediaUrl:'') }
                          postvideo={post?.mediaType== 'video'?(post.status===2?ssd+post.mediaUrl: cdn+'users/'+post.mediaUrl):'' }
                          avater={post?.picture? cdn+'users/'+post.picture:""}
                          user={post?.name}
                          time={post?.createdAt}
                          des={post?.text}
                          item={post}
                          reactions={post?.reactions}
                          comment={post?.comments}
                          showInput={showInput}
                          deleteComments={deleteComments} 
                          mediaType={post?.mediaType}             
                        />
                       </div>
            {/* <h4 className='fw-700 text-grey-900 font-xssss mt-1'>
              {' '}
              {singlePost?.user?.name}{' '}
              <span className='d-block font-xssss fw-500 mt-1 lh-3 text-grey-500'>
                {' '}
                {dayjs(singlePost?.createdAt)?.fromNow()}
              </span>
            </h4> */}
          </div>
{
  //console.log(organizedComments)
}
          <p className='text-black' style={{ fontWeight: 'bold' }}>
            Comments ({singlePost?.comments?.length})
          </p>
          <div className='comments_section'>
            {organizedComments?.map(item => {
              return (
                <SinglePostComment
                  key={item.id}
                  item={item}
                  parentId={parentId}
                  setParentId={setParentId}
                  showInput={showInput}
                  setTaguser ={setTaguser}
                  deleteComments={deleteComments}         
                />
              )
            })}
          </div>
          {inputbox?  (
            <div  className='chat-form'>  {  content?.length>2 && content?.length<15 &&
           <GiphyGif text={content}  onClick={sendGif}></GiphyGif> }
              <div className='form-group'> 
                      <input 
                        value={content}
                        onChange={e => {
                          setContent(e.target.value)
                        }}
                        type='text'
                        className='w-100 bg-grey'
                        placeholder={taguser?'Reply to '+taguser: 'Start typing..'}
                        autoFocus 
                      />
             
                    </div>
            <button type='submit' onClick={SubmitHandler}  className={`bg-current`}>
                      <i className='ti-arrow-right text-white w100'></i>
                    </button>
                    
            
          </div>  ):''}  
          {/* <Tenor
            token='AIzaSyApa_XEwCVwwSYAfKlKBP9vDLKKdgPIqgI'
            onSelect={result => console.log(result)}
          /> */}
        </div>
      </div>
    </div>
  )
}
