import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "./authContext";
import { SocketContext } from "./chatContext";
import { handleUpload } from "../services/cloudinary.service";
import { generateUUID } from "./chatContext";
import profileService from "../services/profile.service";
export const GupShupContext = createContext([]);


const GupShupContextProvider = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  const { socket, audioBlob, setAudioBlob, messageContainerRef } =
    useContext(SocketContext);

  const [group, setGroup] = useState(null);
  var [chat, setChat] = useState([]);
  const [refresh, setrfresh] = useState(1);



  useEffect(() => {
    if(refresh>0) {
      messageContainerRef.current.scrollTo({
        top:
          messageContainerRef.current.scrollHeight -
          messageContainerRef.current.clientHeight,
        behavior: "smooth",
      });
      //setrfresh(refresh+1);
    }
    // Scroll to bottom of messages container when messages update
  
  }, [chat, messageContainerRef]);

  const getChat = async(groupid,groupName) => {
     //console.log('groupName'+groupName);
     //setGroup({id:groupid, name:groupName});
     //joinGroup(groupName);
    //console.log(groupName);
    //if(chat.length<=0) {
     
      var chatmsgs = await profileService.getResult({
        qry: `CALL sp_getGroupChat(${groupid}) `
    });
      if(chatmsgs.length>0) {
        setChat(chatmsgs[0]);
        //setrfresh(refresh+1);
      }
    //}
   
  }

  const setGroupDetails = async(groupid,groupName) => {
    setGroup({id:groupid, name:groupName});

  }

  // const joinGroups = async(groupName) => {
  //   //const gname = groupName?groupName:'buzzGroup';
  //   //console.log('groupName'+groupName);
    
  //     console.log('joining'+groupName)
  //     socket.emit("joinGroup", {
  //       name: currentUser?.displayName,
  //       id: currentUser?.id,
  //       groupName : groupName, 
  //     });
    
  // }

  useEffect(() => {
    //console.log(group);
    //joinGroup(group?.groupName);
    if (currentUser?.id && socket ) {
      socket.emit("joinGroup", {
        name: currentUser?.name,
        id: currentUser?.SID,
        lead: currentUser.lead
      });
    }
 
  }, [socket, currentUser]);

  useEffect(() => {
    if (socket) {
      socket.on("GroupJoined", (data) => {
       // socket.emit("getBuzzChats", data?.id, data.name);
      });
      // socket.on("buzzChatUpdated", (data) => {
      //   console.log(data);
      //   setChat(data?data:null);
      // });
      
      socket.once("groupMessageRec", (data) => {
        //console.log(data);
        if(data.senderid!=currentUser.id)
        setChat([...chat, data]);
      
      });
      socket.once("messageSentToGroup", ({ custom_id, message }) => {
       //console.log(message)
       if(message) {
     
        //setChat([...chat, message]);
        setChat((prevChat) =>
        prevChat.map((msg) => {
          if (msg.custom_id === custom_id) {
            return message;
          }
          
          return msg;
        })
      );
       }
        
      });
      socket.once("messageDeletedFromGroup", (msgId) => {
        //console.log(msgId);
        setChat((prevChat) => prevChat.filter((msg) => msg.id !== msgId));
      });
    }
    return () => {
      if (socket) {
        socket.off("newUser");
        socket.off("GroupJoined");
        socket.off("groupMessageRec");
      }
    };
  }, [socket, group, chat]);

  

  const sendMessageToGroup = async (message) => {
    //console.log(message);
    message.custom_id = generateUUID();
    setChat([...chat, message]);
    socket.emit("sendMessagefromGroup", message);
  };

  const handleSendAudio = async () => {
    const url = await handleUpload(audioBlob);
    sendMessageToGroup({
      message: url,
      senderId: currentUser.id,
      receiverId:group?.id,
      time: new Date(),
      msgType: "audio",
      sender: currentUser,
    });
    setAudioBlob(null);
  };

  const deleteMessage = (id) => {
    socket.emit("deleteMessageFromGroup", { msgId: id, to: group?.name });
    setChat((prevChat) => prevChat.filter((msg) => msg.id !== id));
  };


  return (
    <GupShupContext.Provider
      value={{ sendMessageToGroup, group,getChat,setGroupDetails, chat, handleSendAudio,deleteMessage }}
    >
      {children}
    </GupShupContext.Provider>
  );
};

export default GupShupContextProvider;
