import React, { Component } from "react";
// import NewWindow from 'react-new-window'
import { useEffect } from "react";
import { AuthContext } from "../context/authContext";
// import { getDownloadURL, listAll, deleteObject } from "firebase/storage";
import { useState } from "react";
import { useContext } from "react";
// import { logInWithEmailAndPassword, storage } from "../firebase";
// import { getStorage, ref, uploadBytes } from "firebase/storage";
import {uploadFileToStorageDo, uploadImages } from "../files";
// import "react-slideshow-image/dist/styles.css";
import profileService from "../services/profile.service";
import Ratings from "./Ratings";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useRef } from "react";
import authService from "../services/auth.Service";
// import axios from "axios";
import Avatar from '@mui/material/Avatar';
import { Link } from "react-router-dom";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';


// import { horizontal } from "@cloudinary/url-gen/qualifiers/gradientDirection";
export default function ProfilecardThree(props) {
  const [images, setImages] = useState([]);
  const [activeImage, setActiveImage] = useState([]);
  const [imageName, setImageName] = useState([]);
  const [name, setName] = useState("");
  const [sid, setSID] = useState("");
  const [profile, setProfile] = useState([])
  const [bio, setBio] = useState("")
  const [exps, setExps] = useState(0)
  const [isOpen, setIsOpen] = useState(false);
  const [planetRank, setPlanetRank] = useState(null);
  const cnd ='https://files.geetsuhane.com/';
  
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const sliderRef=useRef(null);
  const { currentUser,getUserDetails,userData } = useContext(AuthContext);
  const [groupPic, setGroupPic] = useState("");
  const cdn="https://files.geetsuhane.com/";

  const queryParams = new URLSearchParams(window.location.search)
  const term = queryParams.get("uid")
  // console.log(userData);


  const getExps = async () => {     
    var exps = await profileService.getResult({
        qry: `CALL sp_totalExps('${term?term: currentUser?.id}');`
    }); 
   //console.log(exps[0][0].grppic);
    setExps(exps[0][0]?exps[0][0].totalExps:0);
    //setGroupPic(exps[0][0]?exps[0][0].grppic:null);
    if(exps[0][0]) {
      if(exps[0][0].grppic)
      setImages((images) => [...images,cdn+exps[0][0].grppic]);
    }
  }
//console.log(userData)
  const onClickPost = () => {
    window.location.href = "#post";
  };
  
  const getImagesfromStorage = async () => {

    //setImageName((imageName) => [...imageName, 'GS']);
    
    setImages((images) => [...images,cdn+ 'album/default/1598006f-52c0-4c0e-81d7-32424b5f59d4.jpeg']);

    // var albumpath = await authService.album({
    //   userId: term?term: currentUser?.id,
    // });
    var albumpath = await profileService.getResult({
      qry: `CALL sp_getAlbum('${currentUser?.id}') `
    })
    if(albumpath) {
      setImages(albumpath?.[0]);
      // console.log(albumpath?.[0]);
    }
  };

  const profileDetails = async () => {
  //localStorage.clear();
   const refid = term?term: currentUser?.id;
   //getProfileImage(refid);
    // var profie = await profileService.getProfile({
    //   uid: refid,
    // });

    var profie = await profileService.getResult({
      qry: `select name,picture,SID from users u  where u.SID='${ currentUser?.sid}';`
  })

    if(profie?.length>0  ) {
      
        setProfile(profie[0]);
        localStorage.setItem("ibuser",profie?.[0].name);
      localStorage.setItem("ibpic",profie?.[0].picture);
      localStorage.setItem("ibid",currentUser?.sid);
      }
     // console.log(profie[0])
}
  const showMomemts =async () => {
    props.onClick(true);
  }

  const getPlanetRanking = async(uid) => {
    var rank = await profileService.getResult({
        qry: `
        select p.planet_rank,p.name from
        (select name,points,RANK() 
        OVER ( ORDER BY points desc) as planet_rank  from grps ) p join usersum us on p.name=us.gname join users u on us.uid=u.id
        where u.SID='${uid?uid: currentUser?.sid}';`
    })
      if(rank) {
        setPlanetRank(rank?.[0]?.planet_rank);
      }
  }
 
  useEffect(() => {
    async function fetchSliderImages() {
     // console.log('hello'+currentUser?.SID)
      await getUserDetails(currentUser?.SID);
      
      await getImagesfromStorage();
      //await profileDetails();
      await getExps();
      getPlanetRanking(currentUser?.SID);
    }
    //getProfileData();
    fetchSliderImages();
    
  }, []);

  return (
    <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
        <div className="h-350">
      <div
        className="card-body  p-0 rounded-xxl overflow-hidden"
       >
       
        <Carousel responsive={responsive} autoPlay={true} autoPlaySpeed={1500} 
          ref={sliderRef}
          className="w-100  bg-image-cover rounded-xxl z-index-0 position-absolute"
          afterChange={(index) => {setActiveImage(imageName[index],index)} 
        } arrows={false} infinite={true}
          >
          {images.map((value, index) => {
            return (
              <div className="each-slide-effect" key={index}>
                <div style={{ backgroundImage: `url(${cdn+'users/'+value?.picture})`,width:"100%", backgroundSize:"335px",backgroundRepeat:"no-repeat",backgroundPosition:"center", height: "335px",opacity:"0.9",filter: "contrast(1.35) brightness(0.60)"}}></div>
              </div>
            );
          })}
        </Carousel>
       
      </div>
     
      <div className="card-body p-0 w-100 ">
        <figure
          className="avatar w100 z-index-1"
          style={{ top: "60px", left: "30px" }}
        >
  <Avatar alt="Planets"  sx={{ width: 112, height: 112 }}              
      src={userData?.picture?(userData?.picture.includes('googleusercontent')?userData?.picture:'https://files.geetsuhane.com/users/'+userData?.picture):`assets/images/user.png`}             
      />
            {/* <img
           
            // src={profile.picture?(profile.picture.includes('googleusercontent')?profile.picture:'https://files.geetsuhane.com/users/'+profile.picture):`assets/images/user.png`} 
            src={currentUser?.picture?(currentUser?.picture.includes('googleusercontent')?currentUser?.picture:'https://files.geetsuhane.com/users/'+currentUser?.picture):`assets/images/user.png`}             alt="avater"
            className="profileImage  p-1 bg-white rounded-circle w-100 cursor-pointer z-index-2 opacity-1"
           
            /> */}
         
        
          <div  className="profile-name">  {currentUser?.name} </div>   
          {currentUser?.vip===1 &&
             <div className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"><img src="https://files.geetsuhane.com/icons/vip08.gif"></img></div>
          }                         

          <div  className="profile-text">  
    <Stack direction="row" spacing={1}>
    <Chip color="secondary" avatar={<Avatar>ID</Avatar>} label= { currentUser?.SID }  />  
    <Avatar  sx={{ width: 35, height: 35 }}    src={cdn+currentUser?.gpic}     />
    {userData?.lead>0 && (
    <Avatar sx={{ width: 28, height: 28 }}><span className="font-xsss">{userData?.lead===1?'PL':(userData?.lead===2 || userData?.lead===3?'PO':(userData?.lead===7?'CF':'F'))}</span></Avatar>
    )}
    </Stack>
    <Ratings uid={userData?.SID}></Ratings>    
    </div>
    {/* <div  className="profile-text"> EXP: { userData?.exps } </div> */}
      </figure>
      
    
      </div>
      </div>
      <div className="text-left">
    {/* <Chip color="secondary" avatar={<Avatar >ID</Avatar>} label= { userData?.gpic }  />   */}
    {/* { userData?.lead<6 && ( */}
            <div className="bg-lightblue rounded-xxl  d-flex ">
            <Avatar  sx={{ width: 92, height: 92 }} src={cdn+userData?.gpic} />
           <div className="pt-2 p-1">
           <div className="text-grey-700 font-xsss p-0">{userData?.gname}</div>
           <div className="text-red font-xssss p-0 m-0  bg-greyblue">{userData?.lead===1?'Planet Lead':(userData?.lead===2 || userData?.lead===3?'Planet Owner':userData?.title?userData.title:userData?.lead===7?'Co-Founder':userData?.lead===8?'Founder':'Proud Member')}</div>
           <div className="text-primary font-xsssss p-0">Planet Ranking {planetRank?' '+planetRank:''}</div>
           </div>
        </div>
    )

    {/* } */}

    
    </div>
      <div className="card-body p-20 w-100 shadow-none mb-0 p-0 border-top-xs position-relative">
        <ul
          className="nav nav-tabs  d-flex product-info-tab border-bottom-0 ps-4"
          id="pills-tab"
          role="tablist"
        >
          
          <li className="list-inline-item me-5">
            {term!=null? (
               <Link to="/chat"
               className="fw-700 font-xssss text-blue-500 pt-3 pb-3 ls-1 d-inline-block"
             
               data-toggle="tab" >
              <i className="feather-mail text-blue"></i> Message
              </Link>
            
               
            ): (
            <Link
              className="fw-700 font-xssss text-red pt-3 pb-3 ls-1 d-inline-block"
              to="/accountinformation"
              data-toggle="tab"
            >
              Update Info
            </Link>
            )}
          </li>
          
        
          
          <li className="list-inline-item me-5">
            <a
              className="fw-700 font-xssss text-navy-500 pt-3 pb-3 ls-1 d-inline-block"
              href="/planets"
              data-toggle="tab"
            >
              Planets
            </a>
          </li>
          {/* <li className="list-inline-item me-5">
            <a
              className="fw-700 font-xssss text-navy-500 pt-3 pb-3 ls-1 d-inline-block"
              href="/notification"
              data-toggle="tab"
            >
              Notifications
            </a>
          </li> */}
          <li className="list-inline-item me-5">
            <a
              className="fw-700 font-xssss text-navy-500 pt-3 pb-3 ls-1 d-inline-block pointer"
              href="/income"
              data-toggle="tab"
            >
              GS Income
            </a>
          </li>
          
        </ul>

        <ul
          className="nav nav-tabs  d-flex product-info-tab border-bottom-0 ps-4"
          id="pills-tab"
          role="tablist"
        >
          
          <li className="list-inline-item me-5">
            <a
              className="fw-700 font-xssss text-navy-500 pt-3 pb-3 ls-1 d-inline-block"
             onClick={() => showMomemts()}
              data-toggle="tab"
            >
              Moments
            </a>
          </li>
          
        
        
          <li className="list-inline-item me-5">
            <a
              className="fw-700 font-xssss text-navy-500 pt-3 pb-3 ls-1 d-inline-block"
              href="/notification"
              data-toggle="tab"
            >
              Notifications
            </a>
          </li>
          <li className="list-inline-item me-5">
            <a
              className="fw-700 font-xssss text-navy-500 pt-3 pb-3 ls-1 d-inline-block pointer"
              href="/events"
              data-toggle="tab"
            >
             Events
            </a>
          </li>
          
        </ul>
      </div>

      
    </div>
    
  );
}
