import React, { useState, useEffect, useContext } from 'react';
import { createSocket } from '../socket'; // Assuming you have a socket creation utility
import './Chessboard.css';
import profileService from '../services/profile.service';
import { AuthContext } from "../context/authContext";

const ChessGame = () => {
  const initialBoard = [
    ["rook-black", "knight-black", "bishop-black", "queen-black", "king-black", "bishop-black", "knight-black", "rook-black"],
    ["pawn-black", "pawn-black", "pawn-black", "pawn-black", "pawn-black", "pawn-black", "pawn-black", "pawn-black"],
    [null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null],
    ["pawn-white", "pawn-white", "pawn-white", "pawn-white", "pawn-white", "pawn-white", "pawn-white", "pawn-white"],
    ["rook-white", "knight-white", "bishop-white", "queen-white", "king-white", "bishop-white", "knight-white", "rook-white"],
  ];

  const { currentUser } = useContext(AuthContext);

  const [board, setBoard] = useState(initialBoard);
  const [turn, setTurn] = useState("white");
  const [selectedPiece, setSelectedPiece] = useState(null);
  const [socket, setSocket] = useState(null); // Use state for socket instance
  const [playerColor, setPlayerColor] = useState(null);
  const [playerChoice, setPlayerChoice] = useState(null);
  const [gameId, setGameId] = useState(""); // The gameId for joining or watching an existing game

  const processChess = async (ctype) => {                                    
    var chessData = await profileService.getResult({
      qry: ` CALL sp_chess('${ctype}',0,'${currentUser?.SID}','','','','')`
    }).then(r => {
      console.log(r?.[0]);
      if (r?.length > 0) {
        if (ctype === 'create') {
          const newGameId = `game${r?.[0][0]?.gameid}`;
          setGameId(newGameId);
          return newGameId; // Return the new game ID
        } else {
          //setAvailableGames(r?.[0] || []); // Fetch available games to join
        }
      } 
    });
    return null; // Return null if no game ID created
  };

  // Connect to the socket once when the player choice is made
  useEffect(() => {
    if (playerChoice && !socket) {
      const newSocket = createSocket(); // Create the socket connection
      setSocket(newSocket); // Save the socket in state

      (async () => {
        if (playerChoice === "new") {
          setPlayerColor('white'); // Set player color here
          const newGameId = await processChess('create');
          if (newGameId) {
            //setPlayerColor('white');
            newSocket.emit('joinGame', { gameId: newGameId, playerId: currentUser?.SID });
          }
        } else if ((playerChoice === "join" || playerChoice === "watch") && gameId) {
          newSocket.emit('joinGame', { gameId });
        }
      })();

      newSocket.on('gameState', ({ board, turn }) => {
        setBoard(board);
        setTurn(turn);
      });

      newSocket.on('connect_error', () => {
        console.error("Connection failed");
      });

      return () => {
        if (newSocket) {
          newSocket.disconnect();
          setSocket(null); // Reset the socket state to null
        }
      };
    }
  }, [playerChoice, gameId, currentUser]);

  // Helper function to validate chess moves
  const isValidMove = (piece, startRow, startCol, targetRow, targetCol) => {
    const rowDiff = Math.abs(targetRow - startRow);
    const colDiff = Math.abs(targetCol - startCol);

    switch (piece) {
      case "pawn-white":
        if (startRow === 6 && targetRow === 4 && colDiff === 0 && board[targetRow][targetCol] === null) return true;
        if (targetRow === startRow - 1 && colDiff === 0 && board[targetRow][targetCol] === null) return true;
        if (targetRow === startRow - 1 && colDiff === 1 && board[targetRow][targetCol]) return true;
        break;
      case "pawn-black":
        if (startRow === 1 && targetRow === 3 && colDiff === 0 && board[targetRow][targetCol] === null) return true;
        if (targetRow === startRow + 1 && colDiff === 0 && board[targetRow][targetCol] === null) return true;
        if (targetRow === startRow + 1 && colDiff === 1 && board[targetRow][targetCol]) return true;
        break;
      case "rook-white":
      case "rook-black":
        if (rowDiff === 0 || colDiff === 0) return true;
        break;
      case "knight-white":
      case "knight-black":
        if ((rowDiff === 2 && colDiff === 1) || (rowDiff === 1 && colDiff === 2)) return true;
        break;
      case "bishop-white":
      case "bishop-black":
        if (rowDiff === colDiff) return true;
        break;
      case "queen-white":
      case "queen-black":
        if (rowDiff === colDiff || rowDiff === 0 || colDiff === 0) return true;
        break;
      case "king-white":
      case "king-black":
        if (rowDiff <= 1 && colDiff <= 1) return true;
        break;
      default:
        return false;
    }

    return false;
  };

  const handleSquareClick = (row, col) => {
    const piece = board[row][col];
    console.log("Clicked on row:", row, "col:", col, "piece:", piece);
  
    // Ensure it's the player's turn to move and they are selecting their own piece
    if (!playerColor || turn !== playerColor) {
      console.log(`Not your turn! Current turn: ${turn}, Player color: ${playerColor}`);
      return;
    }
  
    // If a piece is already selected, try to move it
    if (selectedPiece) {
      console.log(`Attempting to move ${selectedPiece.piece} from (${selectedPiece.row}, ${selectedPiece.col}) to (${row}, ${col})`);
  
      // If the target square is valid (could be empty or opponent's piece), attempt the move
      if (piece === null || !piece.startsWith(playerColor)) {
        socket.emit('makeMove', {
          startRow: selectedPiece.row,
          startCol: selectedPiece.col,
          targetRow: row,
          targetCol: col,
          player: playerColor,
          gameId: gameId,
        });
  
        // Clear selected piece after making the move
        setSelectedPiece(null);
      } else {
        console.log(`Invalid move, cannot move to square occupied by your own piece: ${piece}`);
      }
    } 
    // Select the piece if it's the player's piece and their turn
    else if (piece && piece.startsWith(playerColor)) {
      console.log(`Selected piece: ${piece} at (${row}, ${col})`);
      setSelectedPiece({ row, col, piece }); // Set the selected piece with its position
    } else {
      console.log(`Not your piece to move! Current turn: ${turn}, Selected: ${piece}`);
    }
  };
  
  
  


  // Render the game selection menu
  const renderMenu = () => (
    <div className="game-menu">
      <h2>Select an Option</h2>
      <ul className="menu-list">
        <li onClick={() => setPlayerChoice("new")}>Start a New Game</li>
        <li onClick={() => setPlayerChoice("join")}>Join an Existing Game</li>
        <li onClick={() => setPlayerChoice("watch")}>Watch a Game</li>
      </ul>

      {playerChoice === "join" && (
        <div>
          <h3>Select a Game to Join</h3>
          {/* <ul>
            {availableGames.map((game) => (
              <li key={game.gameId} onClick={() => setGameId(game.gameId)}>
                Game ID: {game.gameId} - Turn: {game.turn}
              </li>
            ))}
          </ul> */}
        </div>
      )}
    </div>
  );

  // Main render
  return (
    <div className="main-content-chess">
      {!playerChoice ? (
        renderMenu() // Show the menu if the player hasn't made a choice yet
      ) : (
        <div className="chessboard">
          {board.map((row, rowIndex) => (
            row.map((piece, colIndex) => (
              <div
                key={`${rowIndex}-${colIndex}`}
                className={`square ${(rowIndex + colIndex) % 2 === 0 ? "light" : "dark"} ${
                  selectedPiece?.row === rowIndex && selectedPiece?.col === colIndex ? "selected" : ""
                }`}
                onClick={() => handleSquareClick(rowIndex, colIndex)}
              >
                {piece && <img src={`https://files.geetsuhane.com/chess/${piece}.png`} alt={piece} className="piece" />}
              </div>
            ))
          ))}
        </div>
      )}
    </div>
  );
};

export default ChessGame;
