import React, { Component , Fragment,useEffect,useState,useContext,useRef } from "react";
import profileService from "../services/profile.service";
import Pagetitle from "../components/Pagetitle";
import { AuthContext } from '../context/authContext'
import { useNavigate } from 'react-router-dom';
import alertService from '../services/sweetAlert';
import Load from "../components/Load";
import { Avatar } from "@mui/material";
import { Link } from "react-router-dom";


function Grps() {

    const [groupList, setGroupList] = useState([]);
    const [dummy, setDummy] = useState([]);
    const cdn = "https://files.geetsuhane.com/";
    const navigate = useNavigate()
    const { currentUser } = useContext(AuthContext);
  const inputRefName = useRef(null);

    if(currentUser==null)
    {
      localStorage.setItem("redirect", "/groups")
      navigate('/login')
    } 

    var CreatePlanet = async () => {
      if(inputRefName.current.value==="") {
       //return alert("name field is empty!");
       alertService.error(
           'Virtual Planet',
           'VP Name can not be empty!'
         )   
         return;
      }
      const confirmed = await alertService.confirm(
          'Virtual Planet',
        'Are you sure you want to create a virual planet and leave the existing group?'
      )
      if (confirmed) {
        var res = await profileService.getResult({
            qry: `CALL sp_createVP('${currentUser?.id}', '${inputRefName.current.value}')`
        });
        if(res[0][0]?.res=='new') {
                console.log(res[0][0]);
                
                alertService.error(
                  'You need to wait',
                  'You need to be atleast 9 months old on GS!'
                )   
              return;
              } else if(res[0][0]?.res=='exists') {
                alertService.error(
                  'Duplicate Name',
                  'This VP Name already exists, please try again!'
                )   
              return;
            } else if(res?.[0][0]?.res==='owner') {
             
              alertService.error(
                  'Error!',
                  `You are already owner of the virual planet`
                )
                return;
               
            } else if(res[0][0]?.res=='success') {

              inputRefName.current.value='';
           
            alertService.info(
                'Congratulations!', 
                'You have sucessfully created a new virtual planet '+ inputRefName.current.value
              )
             await getGroups();
        }
      }
    }

     

    const getGroups = async () => {        
      var grpList = await profileService.getResult({
        qry: `CALL sp_groupDetails('${currentUser?.id}')`
      });
      setGroupList(grpList[0]);
      setDummy(grpList[0]);

      //console.log(groupList);

    }
    const joinGroup = async (actiontype,groupid, groupname) => {
       // console.log(actiontype)
        const confirmed = await alertService.confirm(
            actiontype=='join?Join:Leave'+ ' Planet',
            'Are you sure you want to '+ actiontype+ ' ' + groupname + '?'
          )
          if (confirmed) {
            console.log(res);
            var res = await profileService.getResult({
                qry: `CALL sp_addToGroup('${actiontype}','','${currentUser?.SID}', ${groupid})`
            });
            if(res?.[0][0]?.res==='exists') {
                  
                    const group = res[0][0].res;
                    alertService.error(
                        'Error!',
                        `You are already a member of planet`
                      )
             } else if(res?.[0][0]?.res==='owner') {
              //console.log(res[0]);
             
              alertService.error(
                  'Error!',
                  `You are owner of this virual planet so can not leave`
                )
                return;
            } else {
                const msgtype = (actiontype==='leave'?'Planet Left':'Congratulations');
                const msg = (actiontype==='leave'?'left':'joined');

                alertService.info(
                    msgtype, 
                    'You have sucessfully '+msg+' '+ groupname
                  )
                  if(actiontype==='join') {
                    navigate('/gupshup?groupChat=group');
                  }
            }
          }
          getGroups();
    }
    const searchChange = p => {          
        if(p.length>0) {
            setGroupList(
                groupList.filter(grp => {
                  return grp.name.toLowerCase().includes(p.toLowerCase())
                })
              )
        } else {
            //console.log(dummyList);
            setGroupList(dummy);
        }
       
      }
    useEffect(() => {
        async function fetchGroups() {
          await getGroups();
        }
        fetchGroups();
      }, []);
        return (
            <Fragment>     
                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0">
                            <div className="row">
                              
                                <div className="col-xl-12">
                                {/* <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2 strong pt-2">Create Your Own Virtual Planet</label>
                                                    <input type="text"  className="form-control" placeholder="VP Name" ref={inputRefName} />                                                   
                                                </div>        
                                                <div className="row justify-content-center p-1">
                                                <a  onClick={()=>CreatePlanet()} className="bg-current text-center text-white font-xssss fw-600 p-2 w175 rounded-3 d-inline-block">Create</a>
                                            </div>
                                            <div className="font-xsssss">Note: You need to bring atleast 20 new or existing members and minimum 50000 points should be within 7 days, otherwise 
                                              your new VP will be dissolved. you will not be allowed to create VP again for next 3 months!
                                              </div>
                                              <div className="font-xsssss text-red strong">Top 1 VP owner can become a planet owner of real GS planet and Co-Captian too, if your virtual planet is #1 in month end.</div>
                                            </div>

                                          
                                        </div> */}
                                    
                                    <Pagetitle header="Planets" onChange={searchChange}/>
                                    {!groupList[0]? (
                                                <Load></Load>
                                            ):''
                                        }
                                    
                                    <div className="row ps-2 pe-1 align-items-center">
                                      <div>
                                    
                                      </div>
                                        {groupList.map((value , index) => (
                                        
                                        <div key={index} className="col-md-3 col-sm-3 pe-2 ps-2">
                                             <h4 className="fw-700 font-xsss mt-3 mb-1 text-center">{value.name}</h4>      
                                            <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-0 mt-2 ">
                                            {/* <div className="card-body position-relative h500 bg-image-cover bg-image-center" style={{backgroundImage: `url("${cdn+value.pic}")`}}></div> */}
                                            <center>
                                              <div>
                                              <Avatar  sx={{ width: 160, height: 160 }} src={cdn+value?.pic} ></Avatar>
                                              </div>
                                              <div className="font-grey font-xssss"><img src="https://files.geetsuhane.com/home/points.png" alt="DW Points" className="w20 rounded-3" /> <span className="font-xssss  strong "> {value?.points}</span></div>
                                            <div className="pt-2">
                                            <a  onClick={()=>joinGroup(value?.gname===value?.name?'leave':'join',value.id,value.name,value.pic)} >
                                            <i  className={`${value?.name===value.gname?'bg-red-gradiant feather-user-x':'bg-primary feather-user-plus'} pointer font-xs text-white text-center counter btn-round-sm bg-black me-3`}  ></i>
                                            </a>
                                            <Link to={`/search?groupDetail=${value.name}`}>
                                              <i                                                 
                                                 className='font-xs text-white text-center feather-users counter btn-round-sm bg-black me-3'
                                              ></i>
                                              </Link>
                                            </div>
                                            
                                            </center>  

                                                {/* <div className="card-body d-block w-100   pt-0 text-center position-relative">                                                               
                                                    <span className="text-center ">                                                   
                                                        <a  onClick={()=>joinGroup(value?.gname===value?.name?'leave':'join',value.id,value.name,value.pic)} className={`${value?.name===value.gname?'bg-red-gradiant':'bg-primary'} pointer mt-1 btn pt-2 pb-2 ps-3 pe-3 ms-1 ls-3 rounded-xl  font-xsssss fw-700 ls-lg text-white`}>{value.gname===value.name?'Leave':'Join'}</a>
                                                      
                                                        <a href={`/search?groupDetail=${value.name}`} className="bg-secondary pointer mt-1 btn pt-2 pb-2 ps-3 pe-3 ms-1 ls-3 rounded-xl  font-xsssss fw-700 ls-lg text-white">Members</a>
                                                        <a   className="bg-red-gradiant pointer mt-1 btn pt-2 pb-2 ps-3 pe-3 ms-1 ls-3 rounded-xl  font-xsssss fw-700 ls-lg text-white">
                                                        {value?.points}
                                                        </a>

                                                       
                                                    </span>
                                                </div> */}
                                            </div>
                                        </div>

                                        ))}

                                    </div>
                                </div>               
                            </div>
                        </div>
                        
                    </div>
                </div>
              
            </Fragment>
        );
    }


export default Grps;   