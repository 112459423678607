import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import Darkbutton from "../components/Darkbutton";

import { AuthContext } from "../context/authContext";
import { RWebShare } from "react-web-share";

import "./customStyles.scss";
import { Conditional } from "@cloudinary/url-gen/actions/conditional";
class Header extends Component {
  state = {
    isOpen: false,
    isActive: false,
    isNoti: true,
    searchKey:"",
  };
  static contextType = AuthContext;

  toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });
  toggleActive = () => this.setState({ isActive: !this.state.isActive });
  //toggleisNoti = () => this.setState({ isNoti: !this.state.isNoti });
  

  render() {
    const { isLoggedIn, currentUser, logout } = this.context;
    const navClass = `${this.state.isOpen ? " nav-active" : ""}`;
    const buttonClass = `${this.state.isOpen ? " active" : ""}`;
    const searchClass = `${this.state.isActive ? " show" : ""}`;
    //const notiClass = `${this.state.isNoti ? " show" : ""}`;
    //const img = `${loggedInUserPic ? loggedInUserPic : "assets/images/gs.png"}`;
    const loginOption = `${isLoggedIn ? "Sign Out" : "Sign in"}`;
    //const loginLink = `${loggedInUser ? "logout" : "login"}`;

    const offNotification= () => {
      //console.log(this.state.isNoti);
     this.setState({isNoti:false});
    }
    
    const handleSubmit = e => {
      localStorage.setItem("searchkey",e.target.value);
    //console.log(e.target.value);
    }
  
    if(currentUser?.SID==='Banned') {
      logout();
    }
  
    const logoutFunction = () => {
      logout();
    };

    return (
      <div className="nav-header bg-white shadow-xs border-0">
        
        <div className="nav-top">
          
          <Link to="/">
            {" "}
            <img className="w60" src="https://files.geetsuhane.com/logo/gs_cupid.GIF"></img>
          </Link>
          <Link to="chatlist" className="mob-menu ms-auto me-2 chat-active-btn">
            <i className="feather-message-circle text-grey-900 font-sm btn-round-md bg-greylight"></i>
          </Link>
          <Link to="/notification" 
          className={`me-2 pointer text-center ms-auto menu-icon `}
          onClick={offNotification}
        >
          <span className={`${this.state.isNoti?'dot-count':''} bg-warning hidden`}></span>
          <i className="feather-bell font-md text-current ms-auto"></i>
        </Link>
         
          
          <span
            onClick={this.toggleActive}
           
            className="me-2 menu-search-icon mob-menu pointer"
          >
            <i className="feather-search text-grey-900 font-sm btn-round-sm bg-greylight"  ></i>
          </span>
          <RWebShare
                data={{
                    text: currentUser?.name + " shared GS link-" ,
                    url: window.location.href,
                    title: "Link share",
                }}
               
            >
                 <i className="pointer feather-share text-grey-900 text-dark btn-round-sm font-sm"></i>
                 
            </RWebShare>
            <button
              onClick={this.toggleOpen}
              className={`nav-menu me-0 ms-2 ${buttonClass}`}
            ></button>
        </div>
        

        <form action="/search" className="float-left header-search ms-3">
          <div className="form-group mb-0 icon-input">
            <i className="feather-search font-sm text-grey-400"></i>
            <input
              type="text"
              onChange={handleSubmit}
              placeholder="Start typing to search.."
              className="bg-grey border-0 lh-32 pt-2 pb-2 ps-5 pe-3 font-xssss fw-500 rounded-xl w350 theme-dark-bg"
            />
          </div>
        </form>


       
        <Darkbutton />
        <Link
          to="/chatlist"
          className="p-2 text-center ms-3 menu-icon chat-active-btn"
        >
          <i className="feather-message-square font-sm text-current"></i>
        </Link>

        {isLoggedIn && (
          <React.Fragment>
            <div className="d-flex gap-2 align-items-center">
              {currentUser?.picture ? (
                <Link to="/userpage">
                <img
                  className="rounded-img-sm mx-3 pointer"
                  src={
                    currentUser.picture != "" || !currentUser.picture.includes('sgleusercontent.com')
                      ? "https://files.geetsuhane.com/users/" + currentUser.picture
                      : "/assets/user.png"
                  }
                  alt={currentUser?.name}
                /> </Link>
              ) : (
                <div className="align-items-center bg-grey d-flex justify-content-center rounded-img-sm">
                  <span className="ti-user font-sm"></span>
                </div>
              )}
              <p className="d-none d-sm-none d-md-block mb-md-0 font-xsss">
                {currentUser?.name}
              </p>
             
        
           
            </div>
          </React.Fragment>
        )}
        <div className="p-0 ms-3 menu-icon">
          
          {" "}
          <Link to="/login"
            onClick={logoutFunction}
            className="nav-content-bttn open-font h-auto pt-2 pb-2"
          >
            <span className="cursor-pointer font-xsss">{loginOption}</span>
          </Link>
        </div>

        <nav className={`navigation scroll-bar ${navClass}`}>
          <div className="container ps-0 pe-0">
            <div className="nav-content">
              <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-1 pb-1 mb-2 mt-2">
                <ul className="mb-1 top-content ">
                <li>
                    <Link
                      onClick={this.toggleOpen}
                      to="/"
                      className="nav-content-bttn open-font" >
                      <i className="font-xssss feather-home  btn-round-xs bg-red-gradiant me-3"></i>
                      <span className="font-xssss">Home</span>
                    </Link>
                  </li>  
                 
                  <li>
                    <Link
                      onClick={this.toggleOpen}
                      to="/buzz"
                      className="nav-content-bttn open-font" >
                      <i className="font-xssss feather-tv  btn-round-xs bg-blue-gradiant me-3"></i>
                      <span className="font-xssss">Buzz</span>
                    </Link>
                  </li>      
                  <li>
                    <Link
                      onClick={this.toggleOpen}
                      to="/compose"
                      className="nav-content-bttn open-font" >
                      <i className="font-xssss feather-music  btn-round-xs bg-gold-gradiant me-3"></i>
                      <span className="font-xssss">Compose</span>
                    </Link>
                  </li>    
                     
                  <li>
                    <Link
                      onClick={this.toggleOpen}
                      to="/dw"
                      className="nav-content-bttn open-font">
                      <i className="font-xssss feather-globe btn-round-xs bg-mini-gradiant me-3"></i>
                      <span className="font-xssss">DreamWorld </span>
                    </Link>
                  </li>     
                  <li>
                    <Link
                      onClick={this.toggleOpen}
                      to="/events"
                      className="nav-content-bttn open-font" >
                      <i className="font-xssss feather-activity btn-round-xs bg-mini-gradiant me-3"></i>
                      <span className="font-xssss">Events</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={this.toggleOpen}
                      to="/game"
                      className="nav-content-bttn open-font" >
                      <i className="font-xssss feather-command btn-round-xs bg-red-gradiant me-3"></i>
                      <span className="font-xssss">Games</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={this.toggleOpen}
                      to="/planets"
                      className="nav-content-bttn open-font"
                    >
                      <i className="font-xssss feather-globe btn-round-xs bg-primary-gradiant me-3"></i>
                      <span className="font-xssss">Planets</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={this.toggleOpen}
                      to="/search?members=50"
                      className="nav-content-bttn open-font"
                    >
                      <i className="font-xssss feather-users btn-round-xs bg-primary-gradiant me-3"></i>
                      <span className="font-xssss">Members</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={this.toggleOpen}
                      to="/userpage"
                      className="nav-content-bttn open-font">
                      <i className="font-xssss feather-user btn-round-xs bg-primary-gradiant me-3"></i>
                      <span className="font-xssss">Profile </span>
                    </Link>
                  </li>  
                  <li>
                    <Link
                      onClick={this.toggleOpen}
                      to="/gupshup"
                      className="nav-content-bttn open-font">
                      <i className="font-xssss feather-message-square btn-round-xs bg-gold-gradiant me-3"></i>
                      <span className="font-xssss">Gupshup </span>
                    </Link>
                  </li>
                          
                  {/* <li>
                    <Link
                      onClick={this.toggleOpen}
                      to="/search"
                      className="nav-content-bttn open-font">
                      <i className="font-xssss feather-users btn-round-xs bg-primary-gradiant me-3"></i>
                      <span className="font-xssss">Search </span>
                    </Link>
                  </li>                     */}

                 
                </ul>
              </div>

              <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss">
                <ul className="mb-0">
                <li className="cursor-pointer">
                    <Link to="/login"
                      onClick={logoutFunction}
                      className="nav-content-bttn open-font h-auto pt-2 pb-2"
                    >
                      <i className="font-xs feather-settings me-3 text-grey-500 "></i>
                      <span className="font-xssss">{loginOption}</span>
                    </Link>
                  </li>
                 
                  <li>
                    <Link
                      onClick={this.toggleOpen}
                      to="/privacy"
                      className="nav-content-bttn open-font"
                    >
                      <i className="font-xs text-current feather-shield me-3"></i>
                      <span className="font-xssss">Privacy</span>
                    </Link>
                  </li>
                
                  <li>
                    <Link
                      onClick={this.toggleOpen}
                      to="/terms"
                      className="nav-content-bttn open-font h-auto pt-2 pb-2"
                    >
                      <i className="font-xs feather-flag me-3 text-grey-500"></i>
                      <span className="font-xssss">Terms</span>
                    </Link>
                  </li>
                  
                  <li>
                    <Link
                      onClick={this.toggleOpen}
                      to="/community"
                      className="nav-content-bttn open-font h-auto pt-2 pb-2"
                    >
                      <i className="font-xs feather-users me-3 text-grey-500"></i>
                      <span className="font-xssss">Community</span>
                    </Link>
                  </li>

                 
                </ul>
              </div>
              {/* <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1">
                <div className="nav-caption fw-600 font-xssss text-grey-500">
                  <span></span> Account
                </div>
                <ul className="mb-1">
                
                </ul>
              </div> */}
            </div>
          </div>
        </nav>

        <div className={`app-header-search ${searchClass}`}>
          <form  action={`/search`} className="search-form">
            <div className="form-group searchbox mb-0 border-0 p-1">
              <input
                type="text"
                onChange={handleSubmit}
                className="form-control border-0"
                placeholder="Search..."
              />
              <i className="input-icon">
                <ion-icon
                  name="search-outline"
                  role="img"
                  className="md hydrated"
                  aria-label="search outline"
                ></ion-icon>
              </i>
              <span className="ms-1 mt-1 d-inline-block close searchbox-close">
                <i className="ti-close font-xs" onClick={this.toggleActive}></i>
              </span>
            </div>
            
          </form>
          
        </div>
     
      </div>
    );
  }
}

export default Header;
