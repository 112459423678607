import React, { useContext , Fragment, useState,useEffect } from "react";
import { PayPalButton } from "react-paypal-button-v2";
import profileService from "../services/profile.service";
import alertService from '../services/sweetAlert';
import { AuthContext } from "../context/authContext";
import { useNavigate } from 'react-router-dom';


export default function Sponsor() {
    const { currentUser } = useContext(AuthContext);
    const queryParams = new URLSearchParams(window.location.search)
    const navigate = useNavigate()

    //console.log(currentUser)

    if(currentUser===null)
        {
          localStorage.setItem("redirect", "/");
          navigate('/login');
          return;
        } 

    
        const processPayment = async (details) => {
      
            const res = await profileService.getResult({
              qry :`CALL sp_updateSponsors('${details?.id}','${currentUser?.id}','${details?.purchase_units?.[0]?.amount?.currency_code}',${details?.purchase_units?.[0]?.amount?.value},'paypal','${queryParams.get("stype")}',${queryParams.get("pid")},'${queryParams.get("sname")}')`
            });
         
            if(res?.[0][0]?.res==='success') {
                console.log(res[0]);         
                alertService.info(
                    'Thank you',
                    `Your payment has been successful, thank you for sponsoring!`
                  )
            }     
        }

        const ManualPayment = async (details) => {
            var res;
         
            if (currentUser?.SID==="008") {
                 res = await profileService.getResult({
                    qry :`CALL sp_updateSponsors('${Date.now()}','${currentUser?.id}','${'USD'}',${1.25},'internal','${queryParams.get("stype")}',${queryParams.get("pid")},'${queryParams.get("sname")}')`
                  });
            } 
            if(res?.[0][0]?.res==='success') {
                console.log(res[0]);         
                alertService.info(
                    'Thank you',
                    `Your payment has been successful, thank you for sponsoring!`
                  )
            }     
        }
    
        return (
            <Fragment> 
     
 
            <div className="main-content right-chat-active bg-white">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0" >
                        <div className="row">
                            <div className="col-xl-12 cart-wrapper mb-4">
                                <div className="row">
                                    <div className="font-xsssss">
                                       <span className="text-red strong"> Limited time offer: </span>
                                     <span className="strong ">Just spend $0.99 (approx Rs 80) and get VIP tag for 1 month!</span>   </div>
                                    <div className="col-lg-5 mb-3">
                                   <p className="pt-2 strong font-xssss">Sponsoring games and member covers offers significant visibility and engagement opportunities.</p> 
                                   <p className="text-red font-xssss"> You will receive a VIP tag for an entire month, 
                                   along with your picture featured prominently for at least a week. </p>
                                   <p className="font-xssss"> Additionally, you gain eligibility for monthly awards, which can further elevate your profile, 
                                    while members benefit by receiving 50% of your sponsorship amount, 
                                    creating a win-win situation that fosters GS Family support and drive.</p>
                                   
                                      <PayPalButton
                                        amount={currentUser?.SID==='008'?0.99:2.0}
                                         shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                                        onSuccess={(details, data) => {
                                        //alert("Transaction completed by " + details.payer.name.given_name);

                                        // OPTIONAL: Call your server to save the transaction
                                        // console.log()
                                         processPayment(details);
                                        // fetch("/paypal-transaction-complete", {
                                        //     method: "post",
                                        //     body: JSON.stringify({
                                        //     orderId: data.orderID
                                        //     })
                                        // });
                                        }}
                                        options={{
                                        // clientId: "Ac215ntcp55M-I45EeVq1DzmmjaBpuMwD7PDdwZHqsDT6clkGrLsNLyoYk_GnZIe-iSiwkSedi53QtHF"
                                        clientId: "AYdlTK40QZa2Trr4406UN1mYO3EJ09H_Wl-1yLD9iFvK0ANtdhWuioN3b_un6AV68VkLHbrK-t8dbuAG"
                                        }}
                                    />
                                    {currentUser?.SID==="008" &&
                          <button  onClick={()=>  ManualPayment("")} className=" text-center fw-600 text-uppercase font-xsssss  rounded-3 border-size-md  w85 p-2 text-center ls-1">Submit</button>

                                    }
                          
                                    </div>
                                   
                                </div>
                             
                                <div className="font-xssss"> Terms and conditions apply. We are not resposible for any additional fees applied by your credit/debit card or bank. 
                                    All transactions are non-refunadable. You must be 18 years or older.
                                        </div>
                          


                            </div>
                        </div>
                    </div>
                </div>
            </div>
     
        </Fragment>
        );
        }

