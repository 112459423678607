import React, { Fragment, useContext, useEffect, useState,useCallback } from 'react'

// import ChatUsers from '../components/chat/ChatUsers'
// import Popupchat from '../components/Popupchat'
import AudioRecorder from '../components/chat/audioRecorder'
import AudioPlayer from '../components/chat/audioPlayer'


import { AuthContext } from '../context/authContext'
import { SocketContext } from '../context/chatContext'
import { GupShupContext } from '../context/gupShupContext'
import alertService from '../services/sweetAlert'
import Load from "../components/Load";
import profileService from "../services/profile.service";
import { useNavigate } from 'react-router-dom';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import GiphyGif from '../components/GiphySearch'
//import {useSpring, animated} from "react-spring";


export default function Buzz () {
  const { currentUser } = useContext(AuthContext)
  const { messageContainerRef, windowWidth } = useContext(SocketContext)
  const { sendMessageToGroup,setGroupDetails, chat,getChat, handleSendAudio, deleteMessage } =
    useContext(GupShupContext)
    
  const [newMessage, setNewMessage] = useState('')
  const [taguser, setTaguser] = useState('');
  const [groupPic, setGroupPic] = useState('');
  const [groupId, setGroupid] = useState(null);
  const [groupName, setGroupName] = useState(null);
  const [load,setLoad] = useState(true);
  const [gif,setGif] = useState(null);
  const navigate = useNavigate();

  const fscreen = useFullScreenHandle();

  const queryParams = new URLSearchParams(window.location.search)
  const term = queryParams.get("groupChat");

  const reportChange = useCallback((state, handle) => {
    if (handle === fscreen) {
      console.log('Screen 1 went to', state, handle);
    }
   
  }, [fscreen]);

  const getGroupDetails = async () => { 
   
    // const result = await profileService.checkBanned({
    //   userid: currentUser.id
    //   });
    //   console.log(result?.[0][0].banned); 
    //   if(parseInt(result?.[0][0].banned)>0) {
    //     //console.log(result?.[0][0].banned);
    //     navigate('/login')
    //     return;
    //   }
  

    if(currentUser==null)
    {
      localStorage.setItem("redirect",term? `/gupshup?groupChat=${term}`:`/gupshup`)
      navigate('/login')
    } 

    console.log(term)
    if(term) {

            if(term==='leads' ) {
              if(currentUser?.lead===0) {
                navigate('/login');
              }
              getChat(100,'Leads');
              setGroupid(100);
              setGroupName('Leads');
          
              setGroupDetails(100,'Leads');
              updatereadStatus(100);
            } else {
              console.log(term)
              var exps = await profileService.getResult({
                  qry: `CALL sp_totalExps('${currentUser?.SID}');`
              }); 
          
                    if(exps) {
  
                      setGroupPic(exps[0][0]?.grppic?exps[0][0]?.grppic:null);
  
                      setGroupDetails(exps[0][0]?.gid,exps[0][0]?.gname);
                      console.log(exps[0][0]?.gid);
                      setGroupName(exps[0][0]?.gname);
                      getChat(exps[0][0]?.gid?exps[0][0]?.gid:1,exps[0][0]?.gname?exps[0][0]?.gname:'GupShup');
                      setGroupid(exps[0][0]?.gid?exps[0][0]?.gid:1);
                      updatereadStatus(exps[0][0]?.gid?exps[0][0]?.gid:'1');
                    }
            }
      
        } else {

          getChat(1,'GupShup');
          setGroupid(1);
          setGroupName('GupShup');
      
          setGroupDetails(1,'GupShup')
        }
        setLoad(false);
        
      }

      const updatereadStatus =async (gid) => {
        const pupdate = await profileService.getResult({
          qry :`update msgcount m set mcount=0 where m.receiverId='${currentUser?.id}' and m.senderId='${gid}';`
        });
      }

  //  useEffect(() => {}, [])
  useEffect(() => {
    async function fectGroupDetail() {
      await getGroupDetails();
    }
    fectGroupDetail();
  }, []);
  


  const sendMessage = (msgType,gif) => {
   
      sendMessageToGroup({
        message: msgType==='gif'?gif: newMessage,
        senderId: currentUser.id,
        name: currentUser.name,
        picture: currentUser.picture,
        receiverId: groupId,
        groupName : groupName,
        time: new Date(),
        msgType: msgType,
        sender: currentUser
      })
    
      setNewMessage('');
      setTaguser('');
    
  }
  const submitHandler = e => {
    if (newMessage.length) {
      sendMessage('text','');
      setNewMessage('');
      setTaguser('');
    }
  }

  const deleteMessageAction = async id => {
    const confirmed = await alertService.confirm(
      'Delete Message',
      'Are you sure you want to delete this message?'
    )

    if (confirmed) {
      // delete the item
      await deleteMessage(id)
      alertService.success(
        'Message Deleted',
        'The message has been successfully deleted.'
      )
    }
  }

  const sendGif = (gif) => {
    //console.log(gif);
    sendMessage('gif',gif);
  }

  return (
    <Fragment>
      
       <FullScreen handle={fscreen} onChange={reportChange}>
      {/* {windowWidth > 1000 && <ChatUsers title='Participants' />} */}
      <div className='main-content right-chat-active modal-fullscreen'>
    
        <div className='middle-sidebar-bottom'>
          <div
            className='middle-sidebar-left pe-0'
            style={{ maxWidth: '100%' }}>
           <div className='font-xss text-blue text-center strong'><i className='feather-message-circle'></i> {groupName} <i className='feather-message-circle'></i> </div>
            <div className='row'>
              <div className='col-lg-12 position-relative'>
              
                <div
                  className='chat-wrapper pt-0 w-100 position-relative scroll-bar bg-white theme-dark-bg'
                  ref={messageContainerRef}
                >
                  
                  <div className='chat-body p-3 '>
                    <div className='messages-content pb-3'> 
                    
                      {chat.length
                        ? chat.map(
                            (
                              {
                                id,
                                message,
                                senderid,
                                picture,
                                name,
                                createdAt = new Date(),
                                msgType,
                                SID
                              },
                              index
                            ) => {
                              return (
                                <div
                                  key={`${index}${id}`}
                                  className={`message-item ${
                                    senderid === currentUser?.id
                                      ? 'outgoing-message'
                                      : ''
                                  }`}
                                 
                                >
                                  <div className='message-user pointer' >
                                    <figure className='avatar'>
                                      {picture ? (
                                        <img
                                          className='rounded-img-sm mx-3'
                                          src={
                                            picture.includes('googleusercontent')?picture:
                                            'https://files.geetsuhane.com/users/' +
                                            picture
                                          }
                                          alt=''
                                          onClick={()=>setTaguser(taguser+('@'+name))}
                                        />
                                      ) : (
                                        <div className='align-items-center bg-grey d-flex justify-content-center rounded-img-sm mx-3'>
                                          <span className='ti-user font-sm'></span>
                                        </div>
                                      )}
                                    </figure>
                                    <div className='mt-0' verticalalign='Top'>
                                      <div className='d-flex gap-2' >
                                      <a className='pointer'  href={`/userpage?uid=${SID}`}>
                                        <span className='font-xsssss'>{name}</span>
                                        </a>
                                      </div>
                                      <div className='time'>
                                        {new Date(
                                          createdAt
                                        ).toLocaleTimeString()}
                                        ,{new Date(createdAt).toDateString()}
                                      </div>
                                      
                                    </div>
                                    
                                  </div>
                                  <div className='message-wrap justify-content-between d-flex gap-2 pl-10x align-items-center mt-0 pt-0 font-xssss'>
                                        {msgType === 'audio' ? (
                                          <AudioPlayer url={message} />
                                        ) : (
                                         msgType==='gif'? <img src={message}></img>:
                                         <span className="d-block font-xssss fw-500 mt-1 lh-3 text-blue-500" dangerouslySetInnerHTML={{ __html: (message.replace('http','').replace('www','')) }}></span>

                                        )}
                                        {senderid === currentUser?.id && id && (
                                          <span
                                            onClick={() => {
                                              deleteMessageAction(id)
                                            }}
                                            className='ti-trash cursor-pointer'
                                          ></span>
                                        )}
                                      </div>
                                </div>
                              )
                            }
                          )
                        : <div>
                        <h2 className='text-center'>Welcome {currentUser.name} </h2>
                        <div className='align-items-top'>
                          {groupPic? (
                          <img                                          
                          src={
                            'https://files.geetsuhane.com/'+groupPic
                              }
                            />) :
                            (
                              <img                                          
                              src={                                                                        
                                    currentUser?.picture?.includes('googleusercontent')?currentUser?.picture:
                                    'https://files.geetsuhane.com/users/' +
                                    currentUser?.picture
                                  }
                                  className='w-350 h-350'
                                />
                            )
                          }
                        
                        </div>
                          {
                            load? (
                              <Load/>
                             ):''
                          }
                         
                          {/* <img className="rounded-3 w-350 h-350" src= {"https://files.geetsuhane.com/promo/support1.gif"}></img> */}
                        </div>
                        }

                      <div className='clearfix'></div>
                    </div>
                  </div>  
                </div>
                <div
                  className='chat-bottom dark-bg p-2 shadow-none theme-dark-bg'
                  style={{ width: '98%' }}
                >
                   <i   onClick={fscreen.enter} className="font-xssss text-white feather-maximize-2 btn-round-xs bg-primary-gradiant me-3"></i>
                
                    <span onClick={fscreen.enter} className='font-xsssss pr-5x float-right'>You are in {groupName!='GupShup'?groupName+ '  Planet':'GupShup'}</span>
                    <i
            onClick={() => {
              navigate(-1);
            }}
            className='feather-chevron-left cursor-pointer text-blue  btn-round-sm font-lg'
          ></i>  {  newMessage.length>2 && newMessage.length<15 &&
           <GiphyGif text={newMessage}  onClick={sendGif}></GiphyGif> }
               <form
                    className='chat-form'
                    onSubmit={e => {
                      e.preventDefault()
                      const functionToCall = newMessage
                        ? submitHandler
                        : handleSendAudio
                      functionToCall()
                    }}
                  >
                    <AudioRecorder />
                    
                    <div className='form-group'>
                     
                      <textarea
                     value= {taguser?(!newMessage.includes(taguser)?taguser+' '+ newMessage:newMessage):newMessage}
                      rows={3}
                      cols ={5}
                      maxLength="250"
                      autoFocus
                      placeholder ='Start typing..'
                      onChange={e => {
                        setNewMessage(e.target.value)
                      }}
                      className='chat-textarea w-100 bg-grey font-xsss'/>
                    </div>
                    
                    <button type='submit' className={`bg-current`}>
                      <i className='ti-arrow-right text-white'></i>
                    </button>
                    
                  </form>
                </div>
              
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Popupchat /> */}
      </FullScreen>
    </Fragment>
  )
}
