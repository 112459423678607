import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import alertService from '../services/sweetAlert'
import {  useCallback } from 'react'
import {  animated,useSpring } from 'react-spring';
import profileService from "../services/profile.service";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';





export default function Gifts({giftItem ,setgiftItem })
 {
    //console.log(props);
        // const action = args.action;
        // const header= args.header;
        // const giftItem = args.giftItem;
        //console.log(gift)
        const [isVisible, setIsVisible] = useState(false);
        const [alignment, setAlignment] = React.useState(1);
        const navigate = useNavigate();
    
        const { currentUser } = useContext(AuthContext);
        const [isOpen, setIsOpen] = useState(false);

       const [gold, setGold] = useState(null);
     
        const [ge, setGe] = useState(false);
        const openDrawer = useCallback(() => {setIsVisible(true)}, [])
        //const closeDrawer = useCallback(() => setIsVisible(false), [])
        const closeDrawer = useCallback(() => {
          setIsVisible(false);
          setGe(false);
        }, []);
        const sendGifts = useCallback(() => {setGe(true);setIsOpen(!isOpen)}, [])
        const DnotsendGifts = useCallback(() => setGe(false), [])
       
      
        const [isActive, setIsActive] = useState("silver");
        const [on, toggle] = useState(false);
        //setIsVisible(action);
        const translateY = window.innerHeight / 2  - 0;
        
        const props = useSpring({
            from: { transform: "translateY(0px)" },
            to: [{ transform: ge  ? "translateY(-500px)" : "translateY(0px)" }],           
            config: { duration: "500" },
            loop: false,
            reset: true
          });
          const icons = {
            1:  "silver.png",
             2 :  "gold.png",
             3 : "rose.svg",
             4 : "chocolate-bar.svg",
             5:  "can.svg",
             11 :  "car.svg",
             10 : "jeep.svg",
             12 : "gift.svg",
             9 : "tea.svg",
             14 : "suv-car.svg",
             15 : "sport-car.svg"

};
  const exps = {
    1:  1,
     2 :  10,
     3 : 2,
     4 : 3,
     5:  3,
     11 :  50000,
     10 : 25000,
     12 : 100,
     9 : 2,
     14 : 500000,
     15 : 1000000

};


  const handleChange = (event, value) => {
    console.log(value)
    setAlignment(value);
    DnotsendGifts();
 
  };

  const handleClick= (gift) => {
    setIsActive(gift);
    DnotsendGifts();
    //args.giftItem("");
   //console.log(gift);
  };


  var getGold = async () => {
    var goldStatus =await  profileService.getResult({
        qry: `CALL sp_getGoldBalance('${currentUser?.SID}');`
    });
   setGold(goldStatus?.[0][0]?.gold);
 localStorage.setItem("gold",goldStatus?.[0][0]?.gold)
     
 }
   const updateGifts = async () => {     
    
    var insertGift = await profileService.getResult({
            qry: `CALL sp_insertGiftxn_V1('${currentUser.id}','${localStorage.getItem('uid')}','${isActive}','${localStorage.getItem('giftsource')}',${localStorage.getItem('item')},${exps[isActive]*parseInt(alignment)},${parseInt(alignment)},@res) `
        });
        console.log(insertGift?.[0][0]?.exps);
        getGold();
      
   
  }
   const throwGift = (e) => {
   // console.log(isActive);
    //console.log(parseInt(exps[isActive]) < parseInt(gold));
    if(exps[isActive]*parseInt(alignment) > parseInt(localStorage.getItem("gold")?localStorage.getItem("gold"):0)) {
        alertService.error(
          'Gifts',
          'Currently you do not have enough GS Coins!'
        )  
          navigate("/income");
        
          return;
      } 
    toggle(!on);
    sendGifts();
    //args.animateGift(isActive);
    isActive==""? setIsActive("silver"):setIsActive(isActive);
    updateGifts();
    setGe(true);
    setgiftItem(giftItem+1);
    //console.log(localStorage.getItem('userpic'))
   }

   if (currentUser?.id) {
   
  } else {
    navigate("/login");
  }

  useEffect(() => {
    async function updateData() {
       await  getGold();
    }
    updateData();
    //console.log('hi');
  }, []);

        return (
            <div >
  
            <center>
            

     <div>
          <div className="gifts">
             <div className={isActive==1 || isActive=='' ? 'floating-selected silver pt-5' : 'floating silver pt-5'} onClick={() => handleClick(1)} > 
                <div  >
                  Silver               
                </div>  
                <div>
               
                <img src="https://files.geetsuhane.com/gifts/gold.png" alt="GS Gold" className="w10 rounded-3 " />  <span className="d-none-xsss"> 1</span>
               
                </div>              
              </div> 
              <div className={isActive==3 ? 'floating-selected rose pt-5' : 'floating rose pt-5'} onClick={() => handleClick(3)} > 
                <div  >Rose
                
                </div>  
                <div  >
                <img src="https://files.geetsuhane.com/gifts/gold.png" alt="GS Gold" className="w10 rounded-3 " />  <span className="d-none-xsss"> 2</span>
                </div>  
              </div> 
              <div className={isActive==4 ? 'floating-selected chocolate pt-5' : 'floating chocolate pt-5'} onClick={() => handleClick(4)} > 
                <div  >Chocolate
                 
                </div>  
                <div  >
                <img src="https://files.geetsuhane.com/gifts/gold.png" alt="GS Gold" className="w10 rounded-3 " />  <span className="d-none-xsss"> 3</span>
                </div>  
              </div> 
              {/* <div className={isActive==2 ? 'floating-selected gold pt-5' : 'floating gold pt-5'} onClick={() => handleClick(2)} > 
                <div >Gold
               
                </div>  
                <div  >
                <img src="https://files.geetsuhane.com/gifts/gold.png" alt="GS Gold" className="w10 rounded-3 " /> <span className="d-none-xsss"> 10</span>
                </div>  
              </div>              */}
        
            <div className={isActive==9 ? 'floating-selected tea pt-5' : 'floating tea pt-5'} onClick={() => handleClick(9)} > 
                <div >Tea
               
                </div>  
                <div  >
                <img src="https://files.geetsuhane.com/gifts/gold.png" alt="GS Gold" className="w10 rounded-3 " />  <span className="d-none-xsss"> 2</span>
                </div>  
              </div> 
            </div>
            <div></div>
            <div className="gifts">
           
              {/* <div className={isActive==12 ? 'floating-selected gift pt-5' : 'floating gift pt-5'} onClick={() => handleClick(12)} > 
                <div >Gift
               
                </div>  
                <div  >
                <img src="https://files.geetsuhane.com/gifts/gold.png" alt="GS Gold" className="w10 rounded-3 " />  <span className="d-none-xsss"> 100</span>
                </div>  
              </div>  */}
              <div className={isActive==10 ? 'floating-selected jeep pt-5' : 'floating jeep pt-5'} onClick={() => handleClick(10)} > 
                <div >Jeep
               
                </div>  
                <div  >
                <img src="https://files.geetsuhane.com/gifts/gold.png" alt="GS Gold" className="w10 rounded-3 " />  <span className="d-none-xsss"> 25000</span>
                </div>  
              </div> 
              <div className={isActive==11 ? 'floating-selected car pt-5' : 'floating car pt-5'} onClick={() => handleClick(11)} > 
                <div >Car
               
                </div>  
                <div  >
                <img src="https://files.geetsuhane.com/gifts/gold.png" alt="GS Gold" className="w10 rounded-3 " />  <span className="d-none-xsss"> 50000</span>
                </div>  
              </div> 
              <div className={isActive==14 ? 'floating-selected suv pt-5' : 'floating suv pt-5'} onClick={() => handleClick(14)} > 
                <div >Suv
               
                </div>  
                <div  >
                <img src="https://files.geetsuhane.com/gifts/gold.png" alt="GS Gold" className="w10 rounded-3 " />  <span className="d-none-xsssss"> 500K</span>
                </div>  
              </div> 
              <div className={isActive==15 ? 'floating-selected sport-car pt-5' : 'floating sport-car pt-5'} onClick={() => handleClick(15)} > 
                <div >Sports Car
               
                </div>  
                <div  >
                <img src="https://files.geetsuhane.com/gifts/gold.png" alt="GS Gold" className="w10 rounded-3 " /> <span className="d-none-xssssss">1M</span>
                </div>  
              </div> 
            </div>
            
  
              {/* <img src="https://files.geetsuhane.com/gifts/silver.png" style={{ display: "flex",position:'absolute',left:(700),top:`${y}px` , visibility:`${flyGift=="silver"? "visible":"hidden"}`}}></img> */}        
              <div className="font-xssss" >
               <ToggleButtonGroup
                size="small"
                color="secondary"
                value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform" >
                <ToggleButton value="1">1</ToggleButton>
                <ToggleButton value="100">10</ToggleButton>
                <ToggleButton value="1000">100</ToggleButton>
                <ToggleButton value="10000">1000</ToggleButton>
                <ToggleButton value="100000">10000</ToggleButton>
              </ToggleButtonGroup>
    </div>      
  </div>
 

  <div className="row eft-0 bottom-0" >
            <span className="font-xsssss">Available <img src="https://files.geetsuhane.com/gifts/gold.png" alt="GS Gold" className="w10 rounded-3 " /> {localStorage.getItem("gold")?localStorage.getItem("gold"):gold}</span> 
              <a onClick={() => throwGift() }
                    className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-primary font-xsssss fw-700 ls-lg text-white">Send                 
                    </a>
              </div>
        {/* </Drawer>
         */}
       
        </center>
        <div style={{position:"absolute", marginLeft:"12%"}} >
       <center>  
      <animated.div style={props}>
        {/* <div class={isActive}></div> */}
       
      <img style={{ visibility:`${ge? "visible":"visible"}`}} src={`https://files.geetsuhane.com/gifts/${icons[isActive]}`}></img>
      </animated.div>
      
      </center>     
                </div> 
      
        </div>
               
        );
    }





