import React, { Fragment, useState, useContext, useEffect } from "react";
import Modal from 'react-modal';
import { Avatar, Button, CircularProgress } from "@mui/material";
import Pagetitle from "./Pagetitle";
import { AuthContext } from "../context/authContext";
import profileService from "../services/profile.service";
import alertService from '../services/sweetAlert';
import useSound from 'use-sound';
import toastr from "../services/toastr.service";
import "toastr/build/toastr.min.css";

Modal.setAppElement('#root');

const DwList = ({ isOpen, onClose, dwData, getSingers }) => {
  const { currentUser, sendMessageToGroup } = useContext(AuthContext);
  const cdn = 'https://files.geetsuhane.com/';
  const [playSign] = useSound(cdn + 'sound/sign.mp3');
  const [playLose] = useSound(cdn + 'sound/lose.mp3');
  const [searchKey, setSearchKey] = useState('');
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      getSingers().then(() => {
        setLoading(false);
      });
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const filterDwData = dwData.filter(item =>
    item.uname.toLowerCase().includes(searchKey.toLowerCase())
  );

  const searchChange = p => {
    setSearchKey(p);
  }

  const signDW = async (uname, usid, cost) => {
    const confirmed = await alertService.confirm(
      'Are you sure you want to sign ' + uname + ' it will cost you ' + cost + ' ?'
    );
    if (confirmed) {
      await sendMessageToGroup({
        message: 'Just signed ' + uname + ' in DreamWorld 🤩',
        senderId: currentUser.id,
        name: currentUser?.name,
        picture: currentUser.picture,
        receiverId: 2,
        groupName: 'Activity',
        time: new Date(),
        msgType: 'text',
        sender: currentUser
      });

      const res = await profileService.getResult({
        qry: `CALL sp_updateDW1('sign','${usid}','${currentUser?.SID}', ${cost})`
      });

      if (res?.[0]?.[0]?.failed === 'failed') {
        alertService.error('Not Enough Cash!', `You do not have enough cash to sign: ` + uname);
        playLose();
        return;
      } else if (res?.[0]?.[0]?.exists === 'exists') {
        alertService.error('Not available', `Someone already signed ` + uname);
        playLose();
        return;
      } else if (res?.[0]?.[0]?.duplicate === 'duplicate') {
        alertService.error('Duplicate', `You have already signed ` + uname);
        playLose();
        return;
      } else if (res?.[0]?.[0]?.max === 'max') {
        alertService.error('Max limit', `You have already signed max number of members`);
        playLose();
        return;
      } else if (res?.[0]?.[0]?.wait === 'wait') {
        alertService.error('Too early', `This member is just signed by someone, please try after 5 min`);
        playLose();
        return;
      } else {
        playSign();
        toastr.success(`You have successfully signed ` + uname + ` and you received ` + res?.[0][0]?.wealth);
        await getSingers();
        onClose();
      }
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="DW Members"
      style={customStyles}
    >
      <Fragment>
        <div style={customStyles.modalBody}>
          <Pagetitle text={''} onChange={searchChange} />
          
          {loading ? (
            <div style={customStyles.loadingContainer}>
              <CircularProgress />
            </div>
          ) : (
            <div className="row ps-2 pe-2" style={{ overflowY: 'auto', maxHeight: '60vh' }}>
              {filterDwData.map((value, index) => (
                <div key={index} className="col-md-3 col-sm-4 pe-2 ps-2">
                  <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                    <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                      <center>
                        <Avatar sx={{ width: 80, height: 80 }} src={value?.picture ? (value?.picture.includes('googleusercontent') ? value.picture : cdn + 'users/' + value.picture) : `assets/images/user.png`} />
                      </center>
                      <h4 className="fw-700 font-xsss mt-3 mb-0 text-center">{value?.uname?.substring(0, 21)}</h4>
                      <div className="fw-500 font-xssss text-grey-500 mt-0 mb-0">SID: {value?.usid}</div>
                      <div className="fw-500 font-xssss text-blue mt-0 mb-0 feather-zap strong">{value?.activity}</div>
                      <div className="fw-500 font-xsssss text-red mt-0 mb-0">DWC: {value?.dwcost}</div>
                      <div className="fw-500 font-xsssss text-blue mt-0 mb-0">DWD: {value?.daily_dw}</div>
                      <div className="fw-500 font-xsssss text-red mt-0 mb-0">DWM: {value?.monthly_dw}</div>

                      {currentUser?.SID !== value?.usid && value?.contracted < 10 && (
                        <Button variant="contained" color="secondary" size="small" sx={{ mt: 2 }} onClick={() => signDW(value?.uname, value?.usid, value?.dwcost)}>
                          Sign
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Fixed Close Button at the Bottom */}
        <div style={customStyles.fixedFooter}>
          <Button variant="contained" color="primary" onClick={onClose} fullWidth>
            Close
          </Button>
        </div>
      </Fragment>
    </Modal>
  );
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 600,
    maxHeight: '80vh',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: '50px', // Add space for the fixed footer
  },
  overlay: {
    zIndex: 500,
  },
  modalBody: {
    paddingBottom: '50px', // Space for the footer
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60vh',
  },
  fixedFooter: {
    position: 'fixed',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '100%',
    background: '#fff',
    padding: '10px',
    boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
    zIndex: 601,
  },
};

export default DwList;
